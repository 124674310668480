var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configData && _vm.configData.isShow === 1
    ? _c(
        "div",
        { staticClass: "upload_img acea-row row-between borderPadding" },
        [
          _c("div", [
            _c("div", { staticClass: "header" }, [
              _vm._v(_vm._s(_vm.configData.title)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.configData.tips)),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "box" },
            [
              _c(
                "el-upload",
                {
                  staticClass: "upload-demo",
                  attrs: {
                    action: "",
                    "http-request": _vm.handleUploadForm,
                    headers: _vm.myHeaders,
                    "show-file-list": false,
                    multiple: "",
                  },
                },
                [
                  _c("el-button", {
                    staticClass: "mr15",
                    attrs: { icon: "el-icon-upload2", size: "small" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }