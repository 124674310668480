var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mobile-config" },
    [
      _c(
        "el-form",
        { ref: "formInline" },
        [
          _vm._l(_vm.rCom, function (item, key) {
            return _c(
              "div",
              { key: key },
              [
                _c(item.components.name, {
                  key: key,
                  ref: "childData",
                  refInFor: true,
                  tag: "component",
                  attrs: {
                    configObj: _vm.configObj,
                    configNme: item.configNme,
                    index: _vm.activeIndex,
                    num: item.num,
                  },
                  on: { getConfig: _vm.getConfig },
                }),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c("rightBtn", {
            attrs: { activeIndex: _vm.activeIndex, configObj: _vm.configObj },
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }