var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configObj
    ? _c("div", { staticClass: "page-fooot" }, [
        _c("div", { staticClass: "overflowy", style: _vm.boxStyle }, [
          _c(
            "div",
            { staticClass: "page-foot", on: { click: _vm.showFoot } },
            _vm._l(_vm.menuList, function (item, index) {
              return _c("div", { key: index, staticClass: "foot-item" }, [
                (!item.checked && !item.unchecked) ||
                (item.checked && !item.unchecked) ||
                (!item.checked && item.unchecked)
                  ? _c("div", [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/imgs/moren.jpg"),
                          alt: "",
                        },
                      }),
                    ])
                  : _c("div", [
                      index === _vm.curIndex
                        ? _c("img", { attrs: { src: item.checked, alt: "" } })
                        : _c("img", {
                            attrs: { src: item.unchecked, alt: "" },
                          }),
                    ]),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    style: {
                      color:
                        _vm.curIndex === index
                          ? _vm.configObj.checkColor.color[0].item
                          : _vm.fontColor,
                    },
                  },
                  [_vm._v("\n          " + _vm._s(item.name) + "\n        ")]
                ),
              ])
            }),
            0
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }