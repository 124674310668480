var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configObj
    ? _c("div", [
        _c("div", { staticClass: "mobile-page", style: _vm.boxStyle }, [
          _c(
            "div",
            { staticClass: "list" },
            [
              _vm.articleList.length === 0
                ? _c(
                    "div",
                    {
                      staticClass: "item acea-row row-between",
                      style: _vm.layoutConfig,
                    },
                    [
                      _c("div", { staticClass: "pictrues tui-skeleton-rect" }, [
                        _c("div", { staticClass: "img-box" }, [
                          _c(
                            "div",
                            {
                              staticClass: "empty-box",
                              style: {
                                borderRadius:
                                  _vm.configObj.contentStyle.val + "px",
                              },
                            },
                            [_c("span", { staticClass: "iconfont icon-tu" })]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "text" }, [
                        _c(
                          "div",
                          { staticClass: "text acea-row row-column-between" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "name line2 tui-skeleton-rect",
                                style: _vm.titleColor,
                              },
                              [
                                _vm._v(
                                  "\n              文章标题展示，最多展示2行，仍过长展示不下时展示为…\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "tui-skeleton-rect",
                                style: _vm.timeColor,
                              },
                              [_vm._v("2022-05-05 11:40")]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.articleList.length > 0
                ? _vm._l(_vm.articleList, function (item, index) {
                    return item.status
                      ? _c(
                          "div",
                          {
                            key: index,
                            staticClass: "item acea-row row-between",
                            style: _vm.layoutConfig,
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "pictrues tui-skeleton-rect" },
                              [
                                _c("el-image", {
                                  staticClass: "pic",
                                  style: {
                                    borderRadius:
                                      _vm.configObj.contentStyle.val + "px",
                                  },
                                  attrs: { src: item.cover },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "text" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "name line2 tui-skeleton-rect",
                                  style: _vm.titleColor,
                                },
                                [_vm._v(_vm._s(item.title))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "tui-skeleton-rect",
                                  style: _vm.timeColor,
                                },
                                [_vm._v(_vm._s(item.createTime))]
                              ),
                            ]),
                          ]
                        )
                      : _vm._e()
                  })
                : _vm._e(),
            ],
            2
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }