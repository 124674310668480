var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    { attrs: { size: "small", "label-width": "100px" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "状态：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { type: "button" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableForm.reviewStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableForm, "reviewStatus", $$v)
                                },
                                expression: "tableForm.reviewStatus",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: "" } }, [
                                _vm._v("全部"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "0" } }, [
                                _vm._v("商户创建/撤回"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "1" } }, [
                                _vm._v("平台待审核/商户重新提交审核"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "2" } }, [
                                _vm._v("平台审核通过"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "3" } }, [
                                _vm._v("平台审核失败"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "4" } }, [
                                _vm._v("微信审核成功"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "5" } }, [
                                _vm._v("微信审核失败"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "inline",
                          attrs: { label: "商户分类", prop: "categoryId" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择商户分类",
                                clearable: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableForm.merchant_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableForm, "merchant_type", $$v)
                                },
                                expression: "tableForm.merchant_type",
                              },
                            },
                            _vm._l(_vm.merchantClassify, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          staticStyle: { display: "inline-block" },
                          attrs: { label: "关键字：" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder:
                                  "请输入直播商品名称/ID,商户名称,微信直播间id,微信审核单id",
                                size: "small",
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.keywords,
                                callback: function ($$v) {
                                  _vm.keywords = $$v
                                },
                                expression: "keywords",
                              },
                            },
                            [
                              _c("el-button", {
                                staticClass: "el-button-solt",
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "ID", width: "50", prop: "id" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "直播商品ID", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("filterEmpty")(scope.row.goodsId))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "商品名称",
                  "min-width": "120",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.name + "/" + scope.row.productId)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商品图", "min-width": "60" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: scope.row.coverImgUrlLocal,
                                "preview-src-list": [
                                  scope.row.coverImgUrlLocal,
                                ],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "价格类型", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("priceTypeFilter")(scope.row.priceType)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "价格", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.priceType === 1
                          ? _c("span", [_vm._v(_vm._s(scope.row.price))])
                          : scope.row.priceType === 1
                          ? _c("span", [
                              _vm._v(
                                _vm._s(scope.row.price + "~" + scope.row.price2)
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                "(原)" +
                                  _vm._s(scope.row.price) +
                                  "，(折扣)" +
                                  _vm._s(scope.row.price2)
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商户名称", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.merName))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商户分类", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "widths" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("merCategoryFilter")(scope.row.merType)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sort", "min-width": "60", label: "排序" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "审核状态", "min-width": "110" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.reviewStatus === 3 ||
                        scope.row.reviewStatus === 5
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: scope.row.reviewReason,
                                  placement: "top",
                                },
                              },
                              [
                                scope.row.reviewStatus === 3
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "danger" } },
                                      [_vm._v("平台审核失败")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.reviewStatus === 5
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "danger" } },
                                      [_vm._v("微信审核失败")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                scope.row.reviewStatus === 1
                                  ? _c("el-tag", { attrs: { type: "info" } }, [
                                      _vm._v("平台待审核"),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.reviewStatus === 2
                                  ? _c("el-tag", { attrs: { type: "info" } }, [
                                      _vm._v("平台审核通过"),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.reviewStatus === 4
                                  ? _c("el-tag", [_vm._v("微信审核成功")])
                                  : _vm._e(),
                              ],
                              1
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "150", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.reviewStatus === 1 &&
                        _vm.checkPermi([
                          "platform:mp:live:goods:review",
                          "platform:mp:live:goods:info",
                        ])
                          ? _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toExamine(scope.row)
                                  },
                                },
                              },
                              [_vm._v("审核")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["platform:mp:live:goods:sort"],
                                expression: "['platform:mp:live:goods:sort']",
                              },
                            ],
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleSort(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("排序")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["platform:mp:live:goods:delete"],
                                expression: "['platform:mp:live:goods:delete']",
                              },
                            ],
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableForm.limit,
                  "current-page": _vm.tableForm.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            "custom-class": "demo-drawer",
            direction: "rtl",
            visible: _vm.dialogVisible,
            size: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: function ($event) {
              return _vm.close("ruleForm")
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "demo-drawer_title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v("直播商品审核")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "demo-drawer__content",
            },
            [
              _c("div", { staticClass: "description" }, [
                _c("div", { staticClass: "acea-row" }, [
                  _c("div", { staticClass: "description-term lang" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("商品名称："),
                    ]),
                    _vm._v(_vm._s(_vm.proInfo.name)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [_vm._v("商品ID：")]),
                    _vm._v(_vm._s(_vm.proInfo.productId)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("商户名称："),
                    ]),
                    _vm._v(_vm._s(_vm.proInfo.merName)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("商户分类："),
                    ]),
                    _vm._v(
                      _vm._s(_vm._f("merCategoryFilter")(_vm.proInfo.merType)) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.proInfo.priceType === 1
                    ? _c("div", { staticClass: "description-term sp100" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("一口价："),
                        ]),
                        _vm._v(_vm._s(_vm.proInfo.price) + "\n          "),
                      ])
                    : _c("div", { staticClass: "description-term sp100" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("价格区间："),
                        ]),
                        _vm._v(
                          _vm._s(_vm.proInfo.price + "~" + _vm.proInfo.price2) +
                            "\n          "
                        ),
                      ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("审核结果："),
                    ]),
                    _vm._v(
                      _vm._s(
                        _vm._f("liveReviewStatusFilter")(
                          _vm.proInfo.reviewStatus
                        )
                      ) + "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.proInfo.reviewStatus === 3 ||
                            _vm.proInfo.reviewStatus === 5,
                          expression:
                            "proInfo.reviewStatus === 3 || proInfo.reviewStatus === 5",
                        },
                      ],
                      staticClass: "description-term sp100",
                    },
                    [
                      _c("label", { staticClass: "name" }, [
                        _vm._v("审核失败原因："),
                      ]),
                      _vm._v(_vm._s(_vm.proInfo.reviewReason) + "\n          "),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "acea-row sp100 description-term" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "name",
                          staticStyle: { color: "#606266" },
                        },
                        [_vm._v("商品图：")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "demo-image__preview mr10" },
                        [
                          _c("el-image", {
                            staticStyle: { width: "50px", height: "50px" },
                            attrs: {
                              src: _vm.proInfo.coverImgUrlLocal,
                              "preview-src-list": [
                                _vm.proInfo.coverImgUrlLocal,
                              ],
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "demo-drawer__footer" }, [
            _c("div", { staticClass: "from-foot-btn drawer_fix" }, [
              _c(
                "div",
                { staticClass: "acea-row justify-content" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["platform:seckill:product:audit"],
                          expression: "['platform:seckill:product:audit']",
                        },
                        {
                          name: "debounceClick",
                          rawName: "v-debounceClick",
                          value: function () {
                            _vm.onSubmit(3)
                          },
                          expression:
                            "\n              () => {\n                onSubmit(3);\n              }\n            ",
                        },
                      ],
                      staticStyle: { "margin-left": "0" },
                    },
                    [_vm._v(_vm._s(_vm.loadingBtn ? "提交中 ..." : "审核拒绝"))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["platform:seckill:product:audit"],
                          expression: "['platform:seckill:product:audit']",
                        },
                        {
                          name: "debounceClick",
                          rawName: "v-debounceClick",
                          value: function () {
                            _vm.onSubmit(2)
                          },
                          expression:
                            "\n              () => {\n                onSubmit(2);\n              }\n            ",
                        },
                      ],
                      attrs: { type: "primary" },
                    },
                    [_vm._v(_vm._s(_vm.loadingBtn ? "提交中 ..." : "审核通过"))]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("details-from", { ref: "ProDetail", on: { getList: _vm.getList } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }