var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-cascader", {
        staticClass: "selWidth",
        attrs: {
          "show-all-levels": false,
          options: _vm.merSelect,
          props: _vm.merProps,
          filterable: "",
          clearable: "",
        },
        on: { change: _vm.onChangeMerId },
        model: {
          value: _vm.merIds,
          callback: function ($$v) {
            _vm.merIds = $$v
          },
          expression: "merIds",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }