var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configData && _vm.configData.isShow === 1
    ? _c(
        "div",
        { staticClass: "upload_img acea-row row-between borderPadding" },
        [
          _c("div", [
            _c("div", { staticClass: "header" }, [
              _vm._v(_vm._s(_vm.configData.title)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.configData.tips)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "box", on: { click: _vm.modalPicTap } }, [
            _vm.configData.url
              ? _c("img", { attrs: { src: _vm.configData.url, alt: "" } })
              : _c("div", { staticClass: "upload-box" }, [
                  _c("span", { staticClass: "iconfont icon-tianjia1" }),
                ]),
            _vm._v(" "),
            _vm.configData.url && _vm.configData.type
              ? _c("span", {
                  staticClass: "iconfont-diy iconfont icon-tianjia1",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.bindDelete($event)
                    },
                  },
                })
              : _vm._e(),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }