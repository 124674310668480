var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          {
            staticStyle: { "font-size": "16px" },
            attrs: { slot: "header" },
            slot: "header",
          },
          [_c("div", { staticClass: "page_title" }, [_vm._v("小程序下载")])]
        ),
        _vm._v(" "),
        _c("div", [
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "ml-100 flex-1" }, [
              _c("div", { staticClass: "header_title" }, [
                _vm._v("小程序设置"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "left" }, [_vm._v("小程序名称：")]),
                _vm._v(" "),
                _c("div", { staticClass: "right" }, [_vm._v("一码秦川")]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["platform:wechat:code:download"],
                      expression: "['platform:wechat:code:download']",
                    },
                  ],
                  staticClass: "content-box",
                },
                [
                  _c("div", { staticClass: "left" }, [_vm._v("小程序包：")]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.downCode()
                        },
                      },
                    },
                    [_vm._v("下载小程序")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }