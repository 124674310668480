var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configData
    ? _c(
        "div",
        { staticClass: "borderPadding" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("p", { staticClass: "tips" }, [
            _vm._v("图片建议宽度81*81px；鼠标拖拽左侧圆点可调整导航顺序"),
          ]),
          _vm._v(" "),
          _c(
            "draggable",
            {
              staticClass: "dragArea list-group",
              attrs: {
                list: _vm.configData.list,
                group: "peoples",
                handle: ".iconfont",
              },
            },
            _vm._l(_vm.configData.list, function (item, index) {
              return _c("div", { key: index, staticClass: "box-item" }, [
                _c("div", { staticClass: "left-tool" }, [
                  _c("span", { staticClass: "iconfont icon-tuozhuaidian" }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "right-wrapper" }, [
                  _c("div", { staticClass: "img-wrapper mb10" }, [
                    _c(
                      "div",
                      {
                        staticClass: "img-item",
                        on: {
                          click: function ($event) {
                            return _vm.modalPicTap(index, "checked")
                          },
                        },
                      },
                      [
                        item.checked
                          ? _c("img", { attrs: { src: item.checked, alt: "" } })
                          : _vm._e(),
                        _vm._v(" "),
                        item.checked
                          ? _c("p", { staticClass: "txt" }, [_vm._v("选中")])
                          : _c("div", { staticClass: "img-box" }, [
                              _c("div", { staticClass: "upload-box" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera-solid",
                                  staticStyle: { "font-size": "30px" },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "txt" }, [_vm._v("选中")]),
                            ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "img-item",
                        on: {
                          click: function ($event) {
                            return _vm.modalPicTap(index, "unchecked")
                          },
                        },
                      },
                      [
                        item.unchecked
                          ? _c("img", {
                              attrs: { src: item.unchecked, alt: "" },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        item.unchecked
                          ? _c("p", { staticClass: "txt" }, [_vm._v("未选中")])
                          : _c("div", { staticClass: "img-box" }, [
                              _c("div", { staticClass: "upload-box" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera-solid",
                                  staticStyle: { "font-size": "30px" },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "txt" }, [
                                _vm._v("未选中"),
                              ]),
                            ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "c_row-item mb10" },
                    [
                      _c(
                        "el-col",
                        { staticClass: "label", attrs: { span: 4 } },
                        [_vm._v(" 名称 ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { staticClass: "slider-box", attrs: { span: 19 } },
                        [
                          _c("el-input", {
                            attrs: {
                              size: "small",
                              maxlength: "4",
                              placeholder: "不超过4个字",
                            },
                            model: {
                              value: item.name,
                              callback: function ($$v) {
                                _vm.$set(item, "name", $$v)
                              },
                              expression: "item.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "c_row-item" },
                    [
                      _c(
                        "el-col",
                        { staticClass: "label", attrs: { span: 4 } },
                        [_vm._v(" 链接 ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { staticClass: "slider-box", attrs: { span: 19 } },
                        [
                          _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.getLink(index)
                                },
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    size: "small",
                                    icon: "ios-arrow-forward",
                                    readonly: "",
                                    placeholder: "请选择链接",
                                  },
                                  model: {
                                    value: item.link,
                                    callback: function ($$v) {
                                      _vm.$set(item, "link", $$v)
                                    },
                                    expression: "item.link",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      slot: "append",
                                      icon: "el-icon-arrow-right",
                                    },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "del-box",
                    on: {
                      click: function ($event) {
                        return _vm.deleteMenu(index)
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-error",
                      staticStyle: { "font-size": "20px" },
                    }),
                  ]
                ),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _vm.configData.list && _vm.configData.list.length < 5
            ? _c(
                "el-button",
                {
                  staticClass: "add-btn",
                  attrs: { icon: "el-icon-plus", plain: "" },
                  on: { click: _vm.addMenu },
                },
                [_vm._v("添加图文导航")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("linkaddress", {
            ref: "linkaddres",
            on: { linkUrl: _vm.linkUrl },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "acea-row" }, [
      _c("h4", { staticClass: "mb10" }, [
        _vm._v(
          "在此处设置底部导航，只在微页面中展示，首页设置自定义导航需要在页面设计中设置。"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }