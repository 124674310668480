var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configObj
    ? _c("div", [
        _c(
          "div",
          { staticClass: "home_plant indexList", style: _vm.boxStyle },
          [
            _c("div", { staticClass: "bg_box", style: _vm.boxBgStyle }),
            _vm._v(" "),
            _c("div", { staticClass: "title acea-row row-between-wrapper" }, [
              _c(
                "div",
                { staticClass: "text line1 tui-skeleton-rect acea-row" },
                [
                  _c("el-image", {
                    staticClass: "image",
                    attrs: { src: _vm.src },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "label", style: _vm.titleColor }, [
                    _vm._v(_vm._s(_vm.configObj.titleConfig.val)),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "more tui-skeleton-rect", style: _vm.moreColor },
                [
                  _vm._v("\n        MORE\n        "),
                  _c("span", { staticClass: "iconfont icon-xuanze" }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tips mb10" }, [
              _vm._v("Strolling the community"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "list-wrapper",
                class: "colum" + _vm.listStyle,
                style: _vm.contentConfig,
              },
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "div",
                  {
                    staticClass: "item",
                    style: _vm.contentConfig,
                    attrs: { index: index },
                  },
                  [
                    _c("div", { staticClass: "img-box" }, [
                      _c("div", { staticClass: "mask" }),
                      _vm._v(" "),
                      item.image
                        ? _c("img", { attrs: { src: item.image, alt: "" } })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "empty-box", style: _vm.contentStyle },
                        [_c("span", { staticClass: "iconfont icon-tu" })]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "info" },
                      [
                        _vm.listStyle === 0
                          ? [
                              _c("div", { staticClass: "author" }, [
                                _c("div", { staticClass: "acea" }, [
                                  _c("img", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.avatarShow,
                                        expression: "avatarShow",
                                      },
                                    ],
                                    attrs: {
                                      src: require("@/assets/imgs/ren1.png"),
                                      alt: "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.nicknameShow,
                                          expression: "nicknameShow",
                                        },
                                      ],
                                      style: _vm.nameColor,
                                    },
                                    [_vm._v(_vm._s(item.nickname))]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _vm.titleShow
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "title line1",
                                      staticStyle: { "margin-top": "5px" },
                                      style: _vm.laberColor,
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.store_name) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          : [
                              _vm.titleShow
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "title line1",
                                      style: _vm.laberColor,
                                    },
                                    [_vm._v(_vm._s(item.store_name))]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "author" }, [
                                _c("div", { staticClass: "acea" }, [
                                  _c("img", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.avatarShow,
                                        expression: "avatarShow",
                                      },
                                    ],
                                    attrs: {
                                      src: require("@/assets/imgs/ren1.png"),
                                      alt: "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.nicknameShow,
                                          expression: "nicknameShow",
                                        },
                                      ],
                                      style: _vm.nameColor,
                                    },
                                    [_vm._v(_vm._s(item.nickname))]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "likes" }, [
                                  _c("span", {
                                    staticClass: "iconfont-h5",
                                    class: item.iconfont,
                                  }),
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.likes) +
                                      "\n              "
                                  ),
                                ]),
                              ]),
                            ],
                      ],
                      2
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }