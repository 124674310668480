var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["platform:product:page:list"],
                  expression: "['platform:product:page:list']",
                },
              ],
              ref: "headerBox",
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _vm.checkPermi(["platform:product:tabs:headers"])
                ? _c(
                    "el-tabs",
                    {
                      on: {
                        "tab-click": function ($event) {
                          _vm.getList(1), _vm.goodHeade()
                        },
                      },
                      model: {
                        value: _vm.tableFrom.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "type", $$v)
                        },
                        expression: "tableFrom.type",
                      },
                    },
                    _vm._l(_vm.headeNum, function (item, index) {
                      return _c("el-tab-pane", {
                        key: index,
                        attrs: {
                          label: item.name + "(" + item.count + ")",
                          name: item.type.toString(),
                        },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShowSeach,
                      expression: "isShowSeach",
                    },
                  ],
                  staticClass: "container mt-1",
                },
                [
                  _c(
                    "el-form",
                    { attrs: { inline: "" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品分类：" } },
                        [
                          _c("el-cascader", {
                            ref: "cascader",
                            staticClass: "selWidth",
                            staticStyle: { width: "100%" },
                            attrs: {
                              clearable: "",
                              options: _vm.adminProductClassify,
                              props: _vm.categoryProps,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            model: {
                              value: _vm.tableFrom.categoryId,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableFrom, "categoryId", $$v)
                              },
                              expression: "tableFrom.categoryId",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商户类别：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selWidth",
                              attrs: { clearable: "", placeholder: "请选择" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.isSelf,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "isSelf", $$v)
                                },
                                expression: "tableFrom.isSelf",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "自营", value: 1 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "非自营", value: 0 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["platform:merchant:page:list"],
                              expression: "['platform:merchant:page:list']",
                            },
                          ],
                          attrs: { label: "商户名称：" },
                        },
                        [
                          _c("merchant-name", {
                            on: { getMerId: _vm.getMerId },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品搜索：" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder: "请输入商品名称，关键字",
                                size: "small",
                                clearable: "",
                              },
                              model: {
                                value: _vm.keywords,
                                callback: function ($$v) {
                                  _vm.keywords = $$v
                                },
                                expression: "keywords",
                              },
                            },
                            [
                              _c("el-button", {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["platform:product:page:list"],
                                    expression:
                                      "['platform:product:page:list']",
                                  },
                                ],
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              Number(_vm.tableFrom.type) < 2 &&
              _vm.checkPermi(["platform:product:force:down"])
                ? _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "0px" },
                      attrs: { size: "mini" },
                      on: { click: _vm.batchOff },
                    },
                    [_vm._v("批量强制下架")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", icon: "el-icon-upload2" },
                  on: {
                    click: function ($event) {
                      _vm.isShowSeach = !_vm.isShowSeach
                    },
                  },
                },
                [_vm._v("显示隐藏")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": true,
                "header-cell-style": { fontWeight: "bold" },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              Number(_vm.tableFrom.type) < 3
                ? _c("el-table-column", {
                    key: "2",
                    attrs: { type: "selection", width: "55" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-form",
                          {
                            staticClass: "demo-table-expand",
                            attrs: { "label-position": "left", inline: "" },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "商户类别：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("selfTypeFilter")(props.row.isSelf)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "虚拟销量：" } },
                              [_c("span", [_vm._v(_vm._s(props.row.ficti))])]
                            ),
                            _vm._v(" "),
                            _vm.tableFrom.type == 7
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "拒绝原因：" } },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(props.row.reason)),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm.checkedCities.includes("ID")
                ? _c("el-table-column", {
                    attrs: { prop: "id", label: "ID", "min-width": "50" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("商品图")
                ? _c("el-table-column", {
                    attrs: { label: "商品图", "min-width": "80" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "demo-image__preview" },
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "36px",
                                      height: "36px",
                                    },
                                    attrs: {
                                      src: scope.row.image,
                                      "preview-src-list": [scope.row.image],
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2331550732
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("商品图")
                ? _c("el-table-column", {
                    attrs: {
                      label: "商品名称",
                      prop: "name",
                      "min-width": "180",
                      "show-overflow-tooltip": true,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("商品售价")
                ? _c("el-table-column", {
                    attrs: {
                      label: "商品售价",
                      prop: "price",
                      "min-width": "100",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("商户名称")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "merchantName",
                      label: "商户名称",
                      "min-width": "180",
                      "show-overflow-tooltip": true,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("商户类别")
                ? _c("el-table-column", {
                    attrs: { label: "商户类别", "min-width": "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("selfTypeFilter")(scope.row.isSelf)
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      611084893
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("销量")
                ? _c("el-table-column", {
                    attrs: { prop: "sales", label: "销量", "min-width": "100" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("库存")
                ? _c("el-table-column", {
                    attrs: { prop: "stock", label: "库存", "min-width": "70" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("审核状态")
                ? _c("el-table-column", {
                    attrs: {
                      label: "审核状态",
                      "min-width": "80",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("auditStatusFilter")(
                                      scope.row.auditStatus
                                    )
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4246894879
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "180",
                  fixed: "right",
                  align: "center",
                  "render-header": _vm.renderHeader,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.tableFrom.type === "6" &&
                        _vm.checkPermi(["platform:product:audit"])
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleAudit(scope.row.id, true)
                                  },
                                },
                              },
                              [_vm._v("审核")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.tableFrom.type === "1"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handlePreview(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("预览")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        (_vm.tableFrom.type === "6" ||
                          _vm.tableFrom.type === "1") &&
                        _vm.checkPermi(["platform:product:update"])
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["platform:product:info"],
                                expression: "['platform:product:info']",
                              },
                            ],
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleAudit(scope.row.id, false)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        Number(_vm.tableFrom.type) < 2 &&
                        _vm.checkPermi(["platform:product:force:down"])
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleOff(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("强制下架")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.card_select_show,
              expression: "card_select_show",
            },
          ],
          staticClass: "card_abs",
        },
        [
          [
            _c(
              "div",
              { staticClass: "cell_ht" },
              [
                _c(
                  "el-checkbox",
                  {
                    attrs: { indeterminate: _vm.isIndeterminate },
                    on: { change: _vm.handleCheckAllChange },
                    model: {
                      value: _vm.checkAll,
                      callback: function ($$v) {
                        _vm.checkAll = $$v
                      },
                      expression: "checkAll",
                    },
                  },
                  [_vm._v("全选")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.checkSave()
                      },
                    },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-checkbox-group",
              {
                on: { change: _vm.handleCheckedCitiesChange },
                model: {
                  value: _vm.checkedCities,
                  callback: function ($$v) {
                    _vm.checkedCities = $$v
                  },
                  expression: "checkedCities",
                },
              },
              _vm._l(_vm.columnData, function (item) {
                return _c(
                  "el-checkbox",
                  {
                    key: item,
                    staticClass: "check_cell",
                    attrs: { label: item },
                  },
                  [_vm._v(_vm._s(item))]
                )
              }),
              1
            ),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c("info-from", {
        ref: "infoFrom",
        attrs: {
          componentKey: _vm.componentKey,
          "is-atud": _vm.isAtud,
          "is-show": _vm.isShow,
          productId: _vm.productId,
        },
        on: { subSuccess: _vm.subSuccess },
      }),
      _vm._v(" "),
      _vm.previewVisible
        ? _c(
            "div",
            [
              _c("div", {
                staticClass: "bg",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.previewVisible = false
                  },
                },
              }),
              _vm._v(" "),
              _vm.previewVisible
                ? _c("preview-box", {
                    ref: "previewBox",
                    attrs: { frontDomainUrl: _vm.frontDomainUrl },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }