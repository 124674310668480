var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "deviseBox" },
    [
      _c("div", { staticClass: "devise_head acea-row row-between-wrapper" }, [
        _c(
          "div",
          { staticClass: "acea-row row-baseline" },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v("当前页面：" + _vm._s(_vm.nameTxt)),
            ]),
            _vm._v(" "),
            _c(
              "el-popover",
              {
                attrs: { placement: "bottom", width: "400", trigger: "click" },
                model: {
                  value: _vm.visible,
                  callback: function ($$v) {
                    _vm.visible = $$v
                  },
                  expression: "visible",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "acea-row row-between row-middle" },
                  [
                    _c("el-input", {
                      staticStyle: { width: "260px" },
                      attrs: {
                        placeholder: "必填不超过15个字",
                        maxlength: "15",
                        size: "small",
                      },
                      model: {
                        value: _vm.nameTop,
                        callback: function ($$v) {
                          _vm.nameTop = $$v
                        },
                        expression: "nameTop",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            _vm.visible = false
                            _vm.nameTop = ""
                          },
                        },
                      },
                      [_vm._v("取消")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: [
                              "platform:pagediy:update",
                              "platform:pagediy:save",
                            ],
                            expression:
                              "['platform:pagediy:update', 'platform:pagediy:save']",
                          },
                        ],
                        attrs: { type: "primary", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.saveName(_vm.nameTop, 0)
                          },
                        },
                      },
                      [_vm._v("确定")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("i", {
                  staticClass: "edit el-icon-edit-outline",
                  attrs: { slot: "reference" },
                  slot: "reference",
                }),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "acea-row preview" },
          [
            _c(
              "el-popover",
              {
                attrs: {
                  placement: "top-start",
                  trigger: "hover",
                  disabled: Number(_vm.pageId) === 0,
                },
              },
              [
                _c("div", { attrs: { id: "diyQrcode" } }),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "ht_btn",
                    staticStyle: { "line-height": "9px" },
                    attrs: {
                      slot: "reference",
                      disabled: Number(_vm.pageId) === 0,
                    },
                    slot: "reference",
                  },
                  [
                    _c("i", { staticClass: "iconfont icon-caozuo-xianshi" }),
                    _vm._v("预览"),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "hasPermi",
                    rawName: "v-hasPermi",
                    value: ["platform:pagediy:update", "platform:pagediy:save"],
                    expression:
                      "['platform:pagediy:update', 'platform:pagediy:save']",
                  },
                  {
                    name: "debounceClick",
                    rawName: "v-debounceClick",
                    value: _vm.saveConfig,
                    expression: "saveConfig",
                  },
                ],
                staticClass: "ht_btn mx_12",
              },
              [_vm._v("\n        仅保存\n      ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "hasPermi",
                    rawName: "v-hasPermi",
                    value: ["platform:pagediy:update", "platform:pagediy:save"],
                    expression:
                      "['platform:pagediy:update', 'platform:pagediy:save']",
                  },
                  {
                    name: "debounceClick",
                    rawName: "v-debounceClick",
                    value: function () {
                      _vm.saveConfig(1)
                    },
                    expression:
                      "\n          () => {\n            saveConfig(1);\n          }\n        ",
                  },
                ],
                staticStyle: { color: "#2d8cf0" },
                attrs: { size: "small", loading: _vm.loading },
              },
              [_vm._v("保存关闭")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "66px" } }),
      _vm._v(" "),
      _c(
        "el-card",
        { attrs: { bordered: false, "body-style": { padding: "0" } } },
        [
          _c(
            "div",
            {
              staticClass: "diy-wrapper",
              style: "height:" + (_vm.clientHeight + 9) + "px;",
            },
            [
              _c("div", { staticClass: "left" }, [
                _vm.tabCur == 0
                  ? _c(
                      "div",
                      {
                        staticClass: "wrapper",
                        style: "height:" + _vm.clientHeight + "px;",
                      },
                      _vm._l(_vm.leftMenu, function (item, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "tips",
                                on: {
                                  click: function ($event) {
                                    item.isOpen = !item.isOpen
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.title) +
                                    "\n\n              "
                                ),
                                !item.isOpen
                                  ? _c("i", {
                                      staticClass: "el-icon-arrow-right icon",
                                      attrs: { size: "16" },
                                    })
                                  : _c("i", {
                                      staticClass: "el-icon-arrow-down icon",
                                      attrs: { size: "16" },
                                    }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "draggable",
                              {
                                staticClass: "dragArea list-group",
                                attrs: {
                                  id: "dragArea",
                                  list: item.list,
                                  group: {
                                    name: "people",
                                    pull: "clone",
                                    put: false,
                                  },
                                  clone: _vm.cloneDog,
                                  dragClass: "dragClass",
                                  filter: ".search, .comb, .bar",
                                },
                              },
                              _vm._l(item.list, function (element) {
                                return _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.isOpen,
                                        expression: "item.isOpen",
                                      },
                                    ],
                                    key: element.id,
                                    staticClass: "list-group-item",
                                    class: {
                                      search: element.cname == "搜索框",
                                      comb: element.cname == "组合组件",
                                      bar: element.cname == "商品分类",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addDom(element, 1)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "position",
                                          staticStyle: { display: "none" },
                                        },
                                        [_vm._v("释放鼠标将组建添加到此处")]
                                      ),
                                      _vm._v(" "),
                                      _c("span", {
                                        staticClass: "conter t-icon",
                                        class: element.icon,
                                      }),
                                      _vm._v(" "),
                                      _c("p", { staticClass: "conter" }, [
                                        _vm._v(_vm._s(element.cname)),
                                      ]),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "wrapper",
                        style: "height:" + _vm.clientHeight + "px;",
                      },
                      _vm._l(_vm.urlList, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "link-item" },
                          [
                            _c("div", { staticClass: "name" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "link-txt" }, [
                              _vm._v("地址：" + _vm._s(item.url)),
                            ]),
                            _vm._v(" "),
                            item.parameter
                              ? _c("div", { staticClass: "params" }, [
                                  _c("span", { staticClass: "txt" }, [
                                    _vm._v("参数："),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [_vm._v(_vm._s(item.parameter))]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            item.example
                              ? _c(
                                  "div",
                                  { staticClass: "lable" },
                                  [
                                    _c("p", { staticClass: "txt" }, [
                                      _vm._v("例如：" + _vm._s(item.example)),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "copy copy-data",
                                        attrs: {
                                          size: "small",
                                          "data-clipboard-text": item.example,
                                        },
                                      },
                                      [_vm._v("复制 ")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        )
                      }),
                      0
                    ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "wrapper-con" }, [
                _c("div", { staticClass: "content" }, [
                  _c(
                    "div",
                    {
                      ref: "wrapperCon",
                      staticClass: "contxt",
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "column",
                        overflow: "hidden",
                        height: "100%",
                      },
                      style:
                        "height:" + _vm.clientHeight + "px;overflow: scroll;",
                    },
                    [
                      _c(
                        "div",
                        { class: _vm.activeIndex === -100 ? "border" : "" },
                        [
                          _c("div", { staticClass: "overflowy" }, [
                            _c("div", { staticClass: "picture" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/imgs/electric.png"),
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "page-title",
                                class: { on: _vm.activeIndex == -100 },
                                style: _vm.pageTitle,
                                on: { click: _vm.showTitle },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.titleTxt) +
                                    "\n                  "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "scrollCon" }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  margin: "0 auto",
                                  position: "relative",
                                  height: "auto",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    ref: "imgContainer",
                                    staticClass: "scroll-box",
                                    class: [
                                      _vm.picTxt && _vm.tabValTxt == 2
                                        ? "fullsize noRepeat"
                                        : _vm.picTxt && _vm.tabValTxt == 1
                                        ? "repeat ysize"
                                        : "noRepeat ysize",
                                      _vm.activeIndex === -100 ? "noStyle" : "",
                                    ],
                                    style:
                                      "background-color:" +
                                      (_vm.colorTxt ? _vm.colorPickerTxt : "") +
                                      ";background-image: url(" +
                                      (_vm.picTxt ? _vm.picUrlTxt : "") +
                                      ");",
                                  },
                                  [
                                    _c(
                                      "draggable",
                                      {
                                        staticClass: "dragArea list-group",
                                        staticStyle: {
                                          "padding-bottom": "122px",
                                        },
                                        attrs: {
                                          list: _vm.mConfig,
                                          group: "people",
                                          filter: ".top",
                                          move: _vm.onMove,
                                          animation: "300",
                                        },
                                        on: { change: _vm.onLog },
                                      },
                                      _vm._l(_vm.mConfig, function (item, key) {
                                        return _c(
                                          "div",
                                          {
                                            key: key,
                                            ref: "mConfigItem",
                                            refInFor: true,
                                            staticClass: "mConfig-item",
                                            class: {
                                              on: _vm.activeIndex == key,
                                              top:
                                                item.name === "search_box" ||
                                                item.name === "nav_bar" ||
                                                item.name === "home_footer" ||
                                                item.name === "home_comb",
                                            },
                                            style:
                                              item.name === "home_footer"
                                                ? " position: fixed; z-index: 1;width: 378px;bottom:0"
                                                : "",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.bindconfig(item, key)
                                              },
                                            },
                                          },
                                          [
                                            _c(item.name, {
                                              ref: "getComponentData",
                                              refInFor: true,
                                              tag: "component",
                                              attrs: {
                                                configData: _vm.propsObj,
                                                index: key,
                                                num: item.num,
                                              },
                                              on: { onChange: _vm.onChange },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "delete-box" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "handleType",
                                                    style:
                                                      item.name ===
                                                      "home_footer"
                                                        ? "top: -94px; "
                                                        : "",
                                                  },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "iconfont icon-caozuo-shanchu",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.bindDelete(
                                                            item,
                                                            key
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("div", {
                                                      staticClass:
                                                        "iconfont icon-caozuo-fuzhi1",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.bindAddDom(
                                                            item,
                                                            0,
                                                            key
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("div", {
                                                      staticClass:
                                                        "iconfont icon-caozuo-shang",
                                                      class:
                                                        key === 0 ? "on" : "",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.movePage(
                                                            item,
                                                            key,
                                                            1
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("div", {
                                                      staticClass:
                                                        "iconfont icon-caozuo-xia",
                                                      class:
                                                        key ===
                                                        _vm.mConfig.length - 1
                                                          ? "on"
                                                          : "",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.movePage(
                                                            item,
                                                            key,
                                                            0
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", {
                                              staticClass: "handle",
                                            }),
                                          ],
                                          1
                                        )
                                      }),
                                      0
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt20 btn" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "mb15",
                        attrs: { plain: "" },
                        on: { click: _vm.showTitle },
                      },
                      [_vm._v("页面设置")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content:
                            _vm.pageId === 0
                              ? "暂无历史版本！"
                              : "点击可另存模板",
                          placement: "left-start",
                        },
                      },
                      [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "bottom",
                              width: "400",
                              trigger: "click",
                            },
                            model: {
                              value: _vm.isShow,
                              callback: function ($$v) {
                                _vm.isShow = $$v
                              },
                              expression: "isShow",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "acea-row row-between row-middle",
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "260px" },
                                  attrs: {
                                    placeholder: "必填不超过15个字",
                                    maxlength: "15",
                                    size: "small",
                                  },
                                  model: {
                                    value: _vm.nameContent,
                                    callback: function ($$v) {
                                      _vm.nameContent = $$v
                                    },
                                    expression: "nameContent",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        _vm.isShow = false
                                      },
                                    },
                                  },
                                  [_vm._v("取消")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.saveName(_vm.nameContent, 1)
                                      },
                                    },
                                  },
                                  [_vm._v("确定")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["platform:pagediy:save"],
                                    expression: "['platform:pagediy:save']",
                                  },
                                ],
                                staticClass: "mb15",
                                attrs: {
                                  slot: "reference",
                                  disabled: _vm.pageId === 0,
                                  plain: "",
                                },
                                on: { click: _vm.onClick },
                                slot: "reference",
                              },
                              [_vm._v("另存模板")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content:
                            _vm.pageId === 0
                              ? "暂无历史版本！"
                              : "点击可重置模板",
                          placement: "left-start",
                        },
                      },
                      [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "bottom",
                              width: "400",
                              trigger: "click",
                            },
                            model: {
                              value: _vm.isReast,
                              callback: function ($$v) {
                                _vm.isReast = $$v
                              },
                              expression: "isReast",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "acea-row row-between row-middle",
                              },
                              [
                                _c("p", [_vm._v("是否重置当前页面数据？")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "text-align": "right",
                                      margin: "0",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini", type: "text" },
                                        on: {
                                          click: function ($event) {
                                            _vm.isReast = false
                                          },
                                        },
                                      },
                                      [_vm._v("取消")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                        },
                                        on: { click: _vm.reast },
                                      },
                                      [_vm._v("确定")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "mb15",
                                attrs: {
                                  slot: "reference",
                                  disabled: _vm.pageId === 0,
                                  plain: "",
                                },
                                slot: "reference",
                              },
                              [_vm._v("重置")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "right-box",
                  style: "height:" + _vm.clientHeight + "px;",
                },
                _vm._l(_vm.rConfig, function (item, key) {
                  return _c(
                    "div",
                    {
                      key: key,
                      staticClass: "mConfig-item",
                      staticStyle: { "background-color": "#fff" },
                    },
                    [
                      _c(item.configName, {
                        tag: "component",
                        attrs: {
                          activeIndex: _vm.activeIndex,
                          num: item.num,
                          index: key,
                        },
                        on: { config: _vm.config },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }