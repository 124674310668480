var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "acea-row borderPadding row-middle",
      staticStyle: { "margin-bottom": "20px" },
    },
    [
      _vm.configData.title
        ? _c("div", { staticClass: "title-tips labelwidth" }, [
            _c("span", [_vm._v(_vm._s(_vm.configData.title))]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "radio-boxs ml22" },
        [
          _c(
            "el-checkbox-group",
            {
              attrs: { size: "large" },
              on: {
                change: function ($event) {
                  return _vm.radioChange($event)
                },
              },
              model: {
                value: _vm.configData.activeValue,
                callback: function ($$v) {
                  _vm.$set(_vm.configData, "activeValue", $$v)
                },
                expression: "configData.activeValue",
              },
            },
            _vm._l(_vm.configData.list, function (item, index) {
              return _c(
                "el-checkbox",
                { key: index, attrs: { label: index } },
                [_vm._v("\n        " + _vm._s(item.val) + "\n      ")]
              )
            }),
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }