var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configData
    ? _c("div", { staticClass: "line-box borderPadding mb20" }, [
        _vm.configData.info
          ? _c("div", { staticClass: "title" }, [
              _c("span", [_vm._v(_vm._s(_vm.configData.info))]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "input-box" },
          [
            _c(
              "draggable",
              {
                staticClass: "dragArea list-group",
                attrs: {
                  list: _vm.configData.list,
                  group: "peoples",
                  handle: ".icon",
                },
              },
              _vm._l(_vm.configData.list, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "input-item" },
                  [
                    _c("div", { staticClass: "icon" }, [
                      _c("i", {
                        staticClass: "iconfont icon-tuozhuaidian",
                        staticStyle: { "font-size": "20px", color: "#dddddd" },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "ml20",
                      attrs: {
                        size: "small",
                        maxlength: "10",
                        placeholder: "选填，不超过十个字",
                      },
                      model: {
                        value: item.val,
                        callback: function ($$v) {
                          _vm.$set(item, "val", $$v)
                        },
                        expression: "item.val",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "delete",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.bindDelete(index)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-error",
                          staticStyle: { "font-size": "20px" },
                        }),
                      ]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm.configData.list.length < 20
              ? _c(
                  "div",
                  { staticClass: "add-btn", on: { click: _vm.addHotTxt } },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          width: "100%",
                          height: "40px",
                          "font-size": "12px",
                        },
                        attrs: { icon: "el-icon-plus", plain: "" },
                      },
                      [_vm._v("添加热词")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }