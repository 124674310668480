"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/echartsNew/index"));
var _dashboard = require("@/api/dashboard");
var _permission = require("@/utils/permission");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  data: function data() {
    return {
      optionData: {},
      style: {
        height: '320px'
      },
      timeVal: [],
      dateLimit: '',
      list: [],
      fromList: this.$constants.timeList,
      userView: {},
      pickerOptions: this.$timeOptions,
      //快捷时间选项
      statisticData: [{
        icon: 'icon-zaishoushangpin',
        title: '在售',
        num: 0,
        path: '/product/list',
        color: '#1890FF',
        bgColor: 'rgba(24, 144, 255, 0.08)'
      }, {
        icon: 'icon-daishenheshangpin',
        title: '待审核',
        num: 0,
        path: '/product/list',
        color: '#A277FF',
        bgColor: 'rgba(162, 119, 255, 0.08)'
      }, {
        icon: 'icon-daifahuo2',
        title: '待发货',
        num: 0,
        path: '/order/list',
        color: '#1890FF',
        bgColor: 'rgba(24, 144, 255, 0.08'
      }, {
        icon: 'icon-daihexiao',
        title: '待核销',
        num: 0,
        path: '/order/list',
        color: '#1BBE6B',
        bgColor: 'rgba(27, 190, 107, 0.08)'
      }, {
        icon: 'icon-daituikuan',
        title: '待退款',
        num: 0,
        path: '/order/refund',
        color: '#EF9C20',
        bgColor: 'rgba(239, 156, 32, 0.08)'
      }]
    };
  },
  components: {
    echartsNew: _index.default
  },
  computed: {
    //鉴权处理
    permList: function permList() {
      var arr = [];
      this.nav_list.forEach(function (item) {
        //if (this.checkPermi(item.perms)) {
        arr.push(item);
        //}
      });

      return arr;
    },
    businessList: function businessList() {
      var arr = [];
      this.statisticData.forEach(function (item) {
        //if (this.checkPermi(item.perms)) {
        arr.push(item);
        //}
      });

      return arr;
    }
  },
  created: function created() {
    var end = new Date();
    var start = new Date();
    start.setTime(start.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 29)));
    this.timeVal = [start, end];
  },
  mounted: function mounted() {
    this.dateLimit = 'lately7';
    this.dateLimitPram = 'lately7';
    if ((0, _permission.checkPermi)(['platform:statistics:home:user:channel'])) this.getChannel();
    if ((0, _permission.checkPermi)(['platform:statistics:home:operating:data'])) this.getbusinessData();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    navigatorTo: function navigatorTo(path) {
      this.$router.push(path);
    },
    getbusinessData: function getbusinessData() {
      var _this = this;
      (0, _dashboard.businessData)().then(function (res) {
        _this.statisticData[0].num = res.onSaleProductNum; //在售商品数量
        _this.statisticData[1].num = res.awaitAuditProductNum; //待审核商品数量
        _this.statisticData[2].num = res.notShippingOrderNum; //待发货订单数量
        _this.statisticData[3].num = res.awaitVerificationOrderNum; //待核销订单数量
        _this.statisticData[4].num = res.refundingOrderNum; //待退款订单数量
      });
    },
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.dateLimit = e ? this.timeVal.join(',') : '';
      this.dateLimitPram = e ? this.timeVal.join(',') : '';
    },
    //用户渠道
    getChannel: function getChannel() {
      var _this2 = this;
      (0, _dashboard.userChannelData)().then(function (res) {
        var channelData = res.data;
        channelData = [{
          name: 'H5',
          value: 0,
          channel: 'h5'
        }, {
          name: '小程序',
          value: 0,
          channel: 'routine'
        }, {
          name: '公众号',
          value: 0,
          channel: 'wechat'
        }, {
          name: 'ios',
          value: 0,
          channel: 'ios'
        }, {
          name: '微信ios',
          value: 0,
          channel: 'iosWx'
        }, {
          name: '微信安卓',
          value: 0,
          channel: 'androidWx'
        }];
        var channelArr = new Array();
        channelData.forEach(function (item) {
          res.forEach(function (item1) {
            //通过两层遍历返回api数据的长度，并且得到指定key的渠道name
            if (item.channel == item1.registerType) {
              channelArr.push({
                name: item.name,
                value: item1.num ? item1.num : 0
              });
            }
          });
        });
        _this2.optionData = {
          grid: {
            left: '20%',
            right: '20%',
            top: '20%',
            bottom: '20%'
          },
          //环形图鼠标放上去显示
          tooltip: {
            trigger: 'item',
            enterable: true,
            formatter: function formatter(option) {
              return "".concat(option.seriesName, " <br/> ").concat(option.name, ": ").concat(option.value, "\u4EBA\n            ").concat(option.percent, "%");
            }
          },
          legend: {
            type: 'scroll',
            orient: 'vertical',
            left: 'left',
            top: 'center',
            right: '10%',
            // 距离右侧位置
            itemGap: 18,
            itemHeight: 16,
            // 图标大小设置
            icon: 'circle',
            data: '',
            //y: 'center',
            pageIconSize: 14,
            textStyle: {
              fontSize: 14,
              color: '#333',
              lineHeight: 20,
              // 解决提示语字显示不全
              rich: {
                a: {
                  padding: [0, 10, 0, 10],
                  color: '#FF0000'
                },
                b: {
                  color: '#666'
                }
              }
            },
            formatter: function formatter(name) {
              var num = 0; //人
              var percent = 0; //环比
              var total = 0; //总数量

              for (var i = 0; i < channelArr.length; i++) {
                total += channelArr[i].value;
                if (channelArr[i].name === name) {
                  num = channelArr[i].value; //人
                }
              }

              percent = _this2.$selfUtil.Division(num, total); //除法算出环比
              var arr = [name + '{a|' + num + '人' + '}' + '{b|' + percent ? (percent * 100).toFixed(2) : 0 + '%' + '}'];
              return arr.join('\n');
            }
          },
          series: [{
            name: '访问来源',
            type: 'pie',
            radius: ['30%', '50%'],
            center: ['75%', '50%'],
            //饼图的位置,第一个参数：是指左右，第二个参数：是指上下位置；
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              },
              normal: {
                color: function color(params) {
                  //自定义颜色
                  var colorList = ['#1BBE6B', '#1890FF', '#EF9C20', '#F56C6C', '#A277FF'];
                  return colorList[params.dataIndex];
                }
              }
            },
            labelLine: {
              show: true
            },
            data: channelArr
          }]
        };
      });
    }
  }
};