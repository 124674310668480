var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configObj
    ? _c("div", [
        _c(
          "div",
          { staticClass: "indexList", style: _vm.boxStyle },
          [
            _c("div", { staticClass: "title acea-row row-between-wrapper" }, [
              _c(
                "div",
                { staticClass: "text line1 tui-skeleton-rect acea-row" },
                [
                  _c("el-image", {
                    staticClass: "image",
                    attrs: { src: _vm.src },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "label", style: _vm.titleColor }, [
                    _vm._v(_vm._s(_vm.configObj.titleConfig.val)),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "more tui-skeleton-rect", style: _vm.moreColor },
                [
                  _vm._v("\n        MORE\n        "),
                  _c("span", { staticClass: "iconfont icon-xuanze" }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tips mb10" }, [
              _vm._v("Receive coupons"),
            ]),
            _vm._v(" "),
            _vm.listStyle == 0
              ? [
                  _c("div", { staticClass: "scroll_box" }, [
                    _c(
                      "div",
                      { staticClass: "item no_use", style: _vm.contentConfig },
                      [
                        _vm._m(0),
                        _vm._v(" "),
                        _c("div", { staticClass: "roll up-roll" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "roll down-roll" }),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._m(1),
                  ]),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.listStyle == 1
              ? [
                  _c(
                    "div",
                    { staticClass: "scroll_box" },
                    _vm._l(2, function (item) {
                      return _c(
                        "div",
                        {
                          staticClass: "item1 no_use",
                          style: _vm.contentConfig,
                        },
                        [
                          _vm._m(2, true),
                          _vm._v(" "),
                          _c("div", { staticClass: "get_btn" }, [
                            _vm._v("领取"),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "itemCon acea-row row-between-wrapper" }, [
      _c("div", { staticClass: "text" }, [
        _c("div", { staticClass: "money" }, [
          _c("span", [_vm._v("¥")]),
          _vm._v("100"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info" }, [_vm._v("满1000元可用")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "bnt" }, [_c("span", [_vm._v("立即领取")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "itemCon acea-row row-between-wrapper" }, [
        _c("div", { staticClass: "text" }, [
          _c("div", { staticClass: "money" }, [
            _c("span", [_vm._v("¥")]),
            _vm._v("100"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "info" }, [_vm._v("满1000元可用")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "bnt" }, [_c("span", [_vm._v("已领取")])]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _c("div", { staticClass: "money" }, [
        _c("span", [_vm._v("¥")]),
        _vm._v("100"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "info" }, [_vm._v("满1000元可用")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }