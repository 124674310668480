"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  prepend: function prepend(h, conf, key) {
    return h("template", {
      "slot": "prepend"
    }, [conf.__slot__[key]]);
  },
  append: function append(h, conf, key) {
    return h("template", {
      "slot": "append"
    }, [conf.__slot__[key]]);
  }
};