var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "acea-row row-between mobile-config" }, [
    _vm.configData.title
      ? _c("div", { staticClass: "title-tips" }, [
          _c("span", [_vm._v(_vm._s(_vm.configData.title))]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.configData.cname,
            expression: "configData.cname",
          },
        ],
        staticClass: "title-bar",
      },
      [_vm._v(_vm._s(_vm.configData.cname))]
    ),
    _vm._v(" "),
    _vm.configData.tabVal < 2
      ? _c(
          "div",
          { staticClass: "radio-boxs acea-row" },
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "btn",
                class: _vm.actived === index ? "active" : "",
                on: {
                  click: function ($event) {
                    return _vm.onClick(index)
                  },
                },
              },
              [_vm._v("\n      " + _vm._s(item) + "\n    ")]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }