var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.defaults.tabConfig.tabVal === 0
    ? _c("div", { staticClass: "goods-box borderPadding" }, [
        _c(
          "div",
          { staticClass: "wrapper" },
          [
            _vm.defaults.goodsList.list
              ? [
                  _c(
                    "draggable",
                    {
                      staticClass: "dragArea list-group",
                      attrs: {
                        list: _vm.defaults.goodsList.list,
                        group: "peoples",
                        filter: ".add-item",
                      },
                    },
                    [
                      _vm._l(
                        _vm.defaults.goodsList.list,
                        function (good, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "item" },
                            [
                              _c("img", {
                                attrs: { src: good.image, alt: "" },
                              }),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "el-icon-error",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.bindDelete(index)
                                  },
                                },
                              }),
                            ]
                          )
                        }
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "add-item item",
                          on: { click: _vm.addGoods },
                        },
                        [_c("span", { staticClass: "iconfont icon-tianjia1" })]
                      ),
                    ],
                    2
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }