var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configData && _vm.configData.isShow === 1
    ? _c(
        "div",
        { staticClass: "box borderPadding" },
        [
          _c("div", { staticClass: "c_row-item" }, [
            _c("div", { staticClass: "title labelwidth" }, [
              _vm._v("\n      " + _vm._s(_vm.configData.title) + "\n    "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "slider-box ml22" }, [
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.getLink(_vm.configData.title)
                    },
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        size: "small",
                        icon: "ios-arrow-forward",
                        icon:
                          _vm.configData.title === "链接" ||
                          _vm.configData.title === "更多链接"
                            ? "ios-arrow-forward"
                            : "",
                        readonly:
                          _vm.configData.title === "链接" ||
                          _vm.configData.title === "更多链接"
                            ? true
                            : false,
                        placeholder: _vm.configData.place,
                        maxlength: _vm.configData.max,
                      },
                      model: {
                        value: _vm.configData.val,
                        callback: function ($$v) {
                          _vm.$set(_vm.configData, "val", $$v)
                        },
                        expression: "configData.val",
                      },
                    },
                    [
                      _vm.configData.title === "链接" ||
                      _vm.configData.title === "更多链接"
                        ? _c("el-button", {
                            attrs: {
                              slot: "append",
                              icon: "el-icon-arrow-right",
                            },
                            slot: "append",
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "span",
                        { attrs: { slot: "suffix" }, slot: "suffix" },
                        [_vm._v(_vm._s(_vm.configData.unit || ""))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("linkaddress", {
            ref: "linkaddres",
            on: { linkUrl: _vm.linkUrl },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }