var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          directives: [
            {
              name: "selectLoadMore",
              rawName: "v-selectLoadMore",
              value: _vm.selectLoadMore,
              expression: "selectLoadMore",
            },
          ],
          staticClass: "selWidth",
          attrs: {
            clearable: "",
            filterable: "",
            loading: _vm.loading,
            remote: "",
            multiple: _vm.multiple,
            "remote-method": _vm.remoteMethod,
            placeholder: "请选择商户",
          },
          on: { change: _vm.onChangeMerId },
          model: {
            value: _vm.merId,
            callback: function ($$v) {
              _vm.merId = $$v
            },
            expression: "merId",
          },
        },
        _vm._l(_vm.merchantList, function (user) {
          return _c("el-option", {
            key: user.id,
            attrs: { label: user.name, value: user.id },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }