var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configObj
    ? _c("div", { staticClass: "mobile-page", style: _vm.boxStyle }, [
        _c("div", { staticClass: "empty-box video" }, [
          _c("video", { attrs: { src: _vm.videoUrl } }),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }