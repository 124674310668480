var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configData
    ? _c(
        "div",
        { staticClass: "box borderPadding" },
        [
          _c("Tinymce", {
            staticStyle: { width: "100%", height: "60%" },
            model: {
              value: _vm.configData.val,
              callback: function ($$v) {
                _vm.$set(_vm.configData, "val", $$v)
              },
              expression: "configData.val",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }