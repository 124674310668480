var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hot_imgs borderPadding" },
    [
      _vm.configData.title
        ? _c("div", { staticClass: "title" }, [
            _vm._v("\n    " + _vm._s(_vm.configData.title) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "list-box mt20" },
        [
          _c(
            "draggable",
            {
              staticClass: "dragArea list-group",
              attrs: {
                list: _vm.configData.list,
                group: "peoples",
                handle: ".move-icon",
              },
            },
            _vm._l(_vm.configData.list, function (item, index) {
              return _c("div", { key: index, staticClass: "item" }, [
                _c("div", { staticClass: "move-icon" }, [
                  _c("span", { staticClass: "iconfont icon-tuozhuaidian" }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "img-box",
                    on: {
                      click: function ($event) {
                        return _vm.modalPicTap(item, index)
                      },
                    },
                  },
                  [
                    item.img
                      ? _c("img", { attrs: { src: item.img, alt: "" } })
                      : _c("div", { staticClass: "upload-box" }, [
                          _c("i", {
                            staticClass: "el-icon-camera-solid",
                            staticStyle: { "font-size": "30px" },
                          }),
                        ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "info" },
                  [
                    _vm._l(item.info, function (infos, key) {
                      return _c("div", { key: key, staticClass: "info-item" }, [
                        _c("span", [_vm._v(_vm._s(infos.title))]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "input-box",
                            on: {
                              click: function ($event) {
                                return _vm.getLink(index, key, item.info)
                              },
                            },
                          },
                          [
                            infos.select
                              ? _c(
                                  "el-select",
                                  {
                                    model: {
                                      value: infos.value,
                                      callback: function ($$v) {
                                        _vm.$set(infos, "value", $$v)
                                      },
                                      expression: "infos.value",
                                    },
                                  },
                                  _vm._l(_vm.linkList, function (itm) {
                                    return _c("el-option", {
                                      key: itm.value,
                                      attrs: {
                                        label: itm.label,
                                        value: itm.value,
                                      },
                                    })
                                  }),
                                  1
                                )
                              : _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      size: "small",
                                      readonly:
                                        key == item.info.length - 1
                                          ? true
                                          : false,
                                      placeholder: infos.tips,
                                      maxlength: infos.max,
                                    },
                                    model: {
                                      value: infos.value,
                                      callback: function ($$v) {
                                        _vm.$set(infos, "value", $$v)
                                      },
                                      expression: "infos.value",
                                    },
                                  },
                                  [
                                    key == item.info.length - 1
                                      ? _c("el-button", {
                                          attrs: {
                                            slot: "append",
                                            icon: "el-icon-arrow-right",
                                          },
                                          slot: "append",
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                          ],
                          1
                        ),
                      ])
                    }),
                    _vm._v(" "),
                    _vm.defaults.name !== "pictureCube" &&
                    _vm.defaults.name !== "swiperBg" &&
                    _vm.defaults.name !== "homeComb"
                      ? _c("div", { staticClass: "info-item" }, [
                          _c("span", [_vm._v("状态")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "input-box" },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": true,
                                  "inactive-value": false,
                                  "active-text": "显示",
                                  "inactive-text": "隐藏",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.onchangeIsShow(item.status)
                                  },
                                },
                                model: {
                                  value: item.status,
                                  callback: function ($$v) {
                                    _vm.$set(item, "status", $$v)
                                  },
                                  expression: "item.status",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.configData.isCube
                      ? _c(
                          "div",
                          {
                            staticClass: "delect-btn",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.bindDelete(item, index)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-error",
                              staticStyle: { "font-size": "26px" },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ])
            }),
            0
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.configData.list
        ? [
            _vm.configData.list.length < _vm.configData.maxList
              ? _c(
                  "div",
                  { staticClass: "add-btn" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "button",
                        attrs: { icon: "el-icon-plus", plain: "" },
                        on: { click: _vm.addBox },
                      },
                      [_vm._v("添加版块")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("linkaddress", { ref: "linkaddres", on: { linkUrl: _vm.linkUrl } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }