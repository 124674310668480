var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configObj
    ? _c("div", { staticClass: "mobile-page", style: _vm.boxStyle }, [
        _c("div", { staticClass: "banner" }, [
          _vm.imgSrc
            ? _c("img", {
                style: _vm.contentStyle,
                attrs: { src: _vm.imgSrc, alt: "" },
              })
            : _c("div", { staticClass: "empty-box", style: _vm.contentStyle }, [
                _c("span", { staticClass: "iconfont-diy iconfont icon-tu" }),
              ]),
          _vm._v(" "),
          _c("div", [
            _vm.docType === 0
              ? _c("div", { staticClass: "dot", style: _vm.dotStyle }, [
                  _c("div", {
                    staticClass: "dot-item",
                    style: { background: _vm.configObj.docColor.color[0].item },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "dot-item" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "dot-item" }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.docType === 1
              ? _c(
                  "div",
                  { staticClass: "dot line-dot", style: _vm.dotStyle },
                  [
                    _c("div", {
                      staticClass: "line_dot-item",
                      style: {
                        background: _vm.configObj.docColor.color[0].item,
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "line_dot-item" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "line_dot-item" }),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }