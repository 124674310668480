var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configData
    ? _c(
        "div",
        { staticClass: "c_row-item borderPadding" },
        [
          _c("el-col", { staticClass: "c_label labelwidth" }, [
            _vm._v(_vm._s(_vm.configData.title)),
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { staticStyle: { width: "auto" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.configData.val,
                  callback: function ($$v) {
                    _vm.$set(_vm.configData, "val", $$v)
                  },
                  expression: "configData.val",
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }