var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: "品牌",
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
            closeOnClickModal: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.dialogVisible
            ? _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loadingFrom,
                      expression: "loadingFrom",
                    },
                  ],
                  ref: "dataForm",
                  attrs: {
                    model: _vm.dataForm,
                    "label-width": "100px",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "品牌名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入品牌名称" },
                        model: {
                          value: _vm.dataForm.name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dataForm,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "dataForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品分类", prop: "categoryIdData" } },
                    [
                      _c("el-cascader", {
                        ref: "cascader",
                        staticStyle: { width: "100%" },
                        attrs: {
                          options: _vm.adminProductClassify,
                          props: _vm.categoryProps,
                        },
                        model: {
                          value: _vm.dataForm.categoryIdData,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "categoryIdData", $$v)
                          },
                          expression: "dataForm.categoryIdData",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "品牌图标(180*180)" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("1")
                            },
                          },
                        },
                        [
                          _vm.dataForm.icon
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: { src: _vm.dataForm.icon },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序", prop: "sort" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          min: _vm.$constants.NUM_Range.min,
                          max: _vm.$constants.NUM_Range.max,
                        },
                        model: {
                          value: _vm.dataForm.sort,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dataForm,
                              "sort",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "dataForm.sort",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handleClose("dataForm")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: [
                        "platform:product:brand:add",
                        "platform:product:brand:update",
                      ],
                      expression:
                        "['platform:product:brand:add', 'platform:product:brand:update']",
                    },
                  ],
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.onsubmit("dataForm")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }