var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configObj
    ? _c("div", { staticClass: "mobile-page" }, [
        _c(
          "div",
          { staticClass: "menus", style: _vm.boxStyle },
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "div",
              {
                staticClass: "item line1",
                class: { on: _vm.curIndex === index },
                style: {
                  color:
                    _vm.curIndex === index
                      ? _vm.configObj.checkColor.color[0].item
                      : _vm.fontColor,
                },
              },
              [
                _vm._v("\n      " + _vm._s(item.title) + " "),
                _c("span", { style: _vm.checkColor }),
              ]
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }