var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "hasPermi",
              rawName: "v-hasPermi",
              value: ["platform:order:page:list"],
              expression: "['platform:order:page:list']",
            },
          ],
          staticClass: "box-card",
        },
        [
          _c("div", { staticClass: "clearfix" }, [
            _c(
              "div",
              { staticClass: "container" },
              [
                _c(
                  "el-form",
                  { attrs: { size: "small", "label-width": "100px" } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "订单类型：" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "mr20",
                            attrs: { type: "button", size: "small" },
                            on: {
                              change: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            model: {
                              value: _vm.tableFrom.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableFrom, "type", $$v)
                              },
                              expression: "tableFrom.type",
                            },
                          },
                          _vm._l(_vm.fromType, function (item, i) {
                            return _c(
                              "el-radio-button",
                              { key: i, attrs: { label: item.value } },
                              [_vm._v(_vm._s(item.text) + "\n              ")]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.checkPermi(["platform:order:status:num"])
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "订单状态：" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: { type: "button" },
                                on: { change: _vm.seachList },
                                model: {
                                  value: _vm.tableFrom.status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tableFrom, "status", $$v)
                                  },
                                  expression: "tableFrom.status",
                                },
                              },
                              [
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "all" } },
                                  [
                                    _vm._v(
                                      "全部 " +
                                        _vm._s(
                                          "(" + _vm.orderChartType.all
                                            ? _vm.orderChartType.all
                                            : 0 + ")"
                                        )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "unPaid" } },
                                  [
                                    _vm._v(
                                      "未支付 " +
                                        _vm._s(
                                          "(" + _vm.orderChartType.unPaid
                                            ? _vm.orderChartType.unPaid
                                            : 0 + ")"
                                        )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "notShipped" } },
                                  [
                                    _vm._v(
                                      "未发货 " +
                                        _vm._s(
                                          "(" + _vm.orderChartType.notShipped
                                            ? _vm.orderChartType.notShipped
                                            : 0 + ")"
                                        )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "spike" } },
                                  [
                                    _vm._v(
                                      "待收货 " +
                                        _vm._s(
                                          "(" + _vm.orderChartType.spike
                                            ? _vm.orderChartType.spike
                                            : 0 + ")"
                                        )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "awaitVerification" } },
                                  [
                                    _vm._v(
                                      "待核销\n                " +
                                        _vm._s(
                                          "(" + _vm.orderChartType.verification
                                            ? _vm.orderChartType.verification
                                            : 0 + ")"
                                        )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "complete" } },
                                  [
                                    _vm._v(
                                      "交易完成 " +
                                        _vm._s(
                                          "(" + _vm.orderChartType.complete
                                            ? _vm.orderChartType.complete
                                            : 0 + ")"
                                        )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "refunded" } },
                                  [
                                    _vm._v(
                                      "已退款 " +
                                        _vm._s(
                                          "(" + _vm.orderChartType.refunded
                                            ? _vm.orderChartType.refunded
                                            : 0 + ")"
                                        )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "deleted" } },
                                  [
                                    _vm._v(
                                      "已删除 " +
                                        _vm._s(
                                          "(" + _vm.orderChartType.deleted
                                            ? _vm.orderChartType.deleted
                                            : 0 + ")"
                                        )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "width100",
                        attrs: { label: "时间选择：" },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "mr20",
                            attrs: { type: "button", size: "small" },
                            on: {
                              change: function ($event) {
                                return _vm.selectChange(_vm.tableFrom.dateLimit)
                              },
                            },
                            model: {
                              value: _vm.tableFrom.dateLimit,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableFrom, "dateLimit", $$v)
                              },
                              expression: "tableFrom.dateLimit",
                            },
                          },
                          _vm._l(_vm.fromList.fromTxt, function (item, i) {
                            return _c(
                              "el-radio-button",
                              { key: i, attrs: { label: item.val } },
                              [_vm._v(_vm._s(item.text))]
                            )
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c("el-date-picker", {
                          staticStyle: { width: "220px" },
                          attrs: {
                            "value-format": "yyyy-MM-dd",
                            format: "yyyy-MM-dd",
                            size: "small",
                            type: "daterange",
                            placement: "bottom-end",
                            placeholder: "自定义时间",
                          },
                          on: { change: _vm.onchangeTime },
                          model: {
                            value: _vm.timeVal,
                            callback: function ($$v) {
                              _vm.timeVal = $$v
                            },
                            expression: "timeVal",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "width100", attrs: { label: "订单号：" } },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "selWidth",
                            attrs: {
                              placeholder: "请输入订单号",
                              size: "small",
                              clearable: "",
                            },
                            model: {
                              value: _vm.tableFrom.orderNo,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tableFrom,
                                  "orderNo",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "tableFrom.orderNo",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                slot: "append",
                                icon: "el-icon-search",
                                size: "small",
                              },
                              on: { click: _vm.seachList },
                              slot: "append",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: ["platform:merchant:page:list"],
                            expression: "['platform:merchant:page:list']",
                          },
                        ],
                        attrs: { label: "商户名称：" },
                      },
                      [_c("merchant-name", { on: { getMerId: _vm.getMerId } })],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "width100" },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["platform:export:order:excel"],
                                expression: "['platform:export:order:excel']",
                              },
                            ],
                            attrs: { size: "small" },
                            on: { click: _vm.exports },
                          },
                          [_vm._v("导出")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "table",
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
                "header-cell-style": { fontWeight: "bold" },
                "row-key": function (row) {
                  return row.orderNo
                },
              },
            },
            [
              _vm.checkedCities.includes("订单号")
                ? _c("el-table-column", {
                    attrs: { label: "订单号", "min-width": "220" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "acea-row" },
                                [
                                  _c(
                                    "font",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: scope.row.type === 1,
                                          expression: "scope.row.type === 1",
                                        },
                                      ],
                                      attrs: { color: "#f6ae02" },
                                    },
                                    [_vm._v("[视频号]")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "font",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: scope.row.type === 2,
                                          expression: "scope.row.type === 2",
                                        },
                                      ],
                                      attrs: { color: "#1890ff" },
                                    },
                                    [_vm._v("[秒杀]")]
                                  ),
                                  _vm._v(" "),
                                  _c("span", {
                                    staticClass: "ml5",
                                    class:
                                      parseInt(scope.row.refundStatus) > 0
                                        ? "red"
                                        : "",
                                    staticStyle: { display: "block" },
                                    domProps: {
                                      textContent: _vm._s(scope.row.orderNo),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        parseInt(scope.row.refundStatus) > 0,
                                      expression:
                                        "parseInt(scope.row.refundStatus) > 0",
                                    },
                                  ],
                                  staticStyle: {
                                    color: "#ed4014",
                                    display: "block",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("orderRefundStatusFilter")(
                                        scope.row.refundStatus
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: scope.row.isUserDel,
                                      expression: "scope.row.isUserDel",
                                    },
                                  ],
                                  staticStyle: {
                                    color: "#ed4014",
                                    display: "block",
                                  },
                                },
                                [_vm._v("用户已删除")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: scope.row.isMerchantDel,
                                      expression: "scope.row.isMerchantDel",
                                    },
                                  ],
                                  staticStyle: {
                                    color: "#ed4014",
                                    display: "block",
                                  },
                                },
                                [_vm._v("商户已删除")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1667154584
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("商户名称")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "merName",
                      label: "商户名称",
                      "min-width": "150",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("收货人")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "nickName",
                      label: "用户昵称",
                      "min-width": "150",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  class:
                                    scope.row.isLogoff == true ? "red" : "",
                                },
                                [_vm._v(_vm._s(scope.row.nickName))]
                              ),
                              _vm._v(" "),
                              scope.row.isLogoff == true
                                ? _c(
                                    "span",
                                    {
                                      class:
                                        scope.row.isLogoff == true ? "red" : "",
                                    },
                                    [_vm._v("|")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.isLogoff == true
                                ? _c("span", { staticClass: "red" }, [
                                    _vm._v("(已注销)"),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4076123740
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("实际支付")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "payPrice",
                      label: "实际支付",
                      "min-width": "80",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("支付方式")
                ? _c("el-table-column", {
                    attrs: { label: "支付方式", "min-width": "80" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("payTypeFilter")(scope.row.payType)
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2281245087
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("订单状态")
                ? _c("el-table-column", {
                    attrs: { label: "订单状态", "min-width": "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.refundStatus === 3
                                ? _c("span", { staticClass: "fontColor3" }, [
                                    _vm._v("已退款"),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("orderStatusFilter")(
                                          scope.row.status
                                        )
                                      )
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3658376581
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("下单时间")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "下单时间",
                      "min-width": "140",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "150",
                  fixed: "right",
                  align: "center",
                  "render-header": _vm.renderHeader,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.checkPermi(["platform:order:info"])
                          ? _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.onOrderDetails(scope.row.orderNo)
                                  },
                                },
                              },
                              [_vm._v("订单详情")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.card_select_show,
              expression: "card_select_show",
            },
          ],
          staticClass: "card_abs",
        },
        [
          [
            _c(
              "div",
              { staticClass: "cell_ht" },
              [
                _c(
                  "el-checkbox",
                  {
                    attrs: { indeterminate: _vm.isIndeterminate },
                    on: { change: _vm.handleCheckAllChange },
                    model: {
                      value: _vm.checkAll,
                      callback: function ($$v) {
                        _vm.checkAll = $$v
                      },
                      expression: "checkAll",
                    },
                  },
                  [_vm._v("全选")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.checkSave()
                      },
                    },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-checkbox-group",
              {
                on: { change: _vm.handleCheckedCitiesChange },
                model: {
                  value: _vm.checkedCities,
                  callback: function ($$v) {
                    _vm.checkedCities = $$v
                  },
                  expression: "checkedCities",
                },
              },
              _vm._l(_vm.columnData, function (item) {
                return _c(
                  "el-checkbox",
                  {
                    key: item,
                    staticClass: "check_cell",
                    attrs: { label: item },
                  },
                  [_vm._v(_vm._s(item))]
                )
              }),
              1
            ),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c("details-from", {
        ref: "orderDetail",
        attrs: { orderNo: _vm.orderNo },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }