var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "infoBox" },
    [
      _c(
        "el-drawer",
        {
          ref: "drawer",
          staticClass: "infoBox",
          attrs: {
            visible: _vm.dialogVisible,
            title: _vm.isAtud ? "商品审核" : "商品详情",
            direction: _vm.direction,
            "append-to-body": true,
            "custom-class": "demo-drawer",
            size: "1000px",
            wrapperClosable: _vm.isAtud ? false : true,
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.onClose,
          },
        },
        [
          _c("div", { staticClass: "demo-drawer__content" }, [
            _vm.formValidate && _vm.isShow
              ? _c(
                  "div",
                  { staticClass: "divBox" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [
                        _c(
                          "el-tabs",
                          {
                            model: {
                              value: _vm.currentTab,
                              callback: function ($$v) {
                                _vm.currentTab = $$v
                              },
                              expression: "currentTab",
                            },
                          },
                          [
                            _c("el-tab-pane", {
                              attrs: { label: "商品信息", name: "0" },
                            }),
                            _vm._v(" "),
                            _c("el-tab-pane", {
                              attrs: { label: "商品详情", name: "1" },
                            }),
                            _vm._v(" "),
                            _c("el-tab-pane", {
                              attrs: { label: "其他设置", name: "2" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        ref: "formValidate",
                        staticClass: "formValidate",
                        attrs: {
                          model: _vm.formValidate,
                          "label-width": "120px",
                        },
                        nativeOn: {
                          submit: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _c(
                          "el-row",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.currentTab === "0",
                                expression: "currentTab === '0'",
                              },
                            ],
                            attrs: { gutter: 24 },
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "商品名称：",
                                      prop: "name",
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "spfont" }, [
                                      _vm._v(_vm._s(_vm.formValidate.name)),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "商品简介：",
                                      prop: "intro",
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "spfont" }, [
                                      _vm._v(_vm._s(_vm.formValidate.intro)),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "商品关键字：",
                                      prop: "keyword",
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "spfont" }, [
                                      _vm._v(_vm._s(_vm.formValidate.keyword)),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              _vm._b({}, "el-col", _vm.grid2, false),
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "单位：",
                                      prop: "unitName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入单位",
                                        readonly: _vm.isDisabled,
                                      },
                                      model: {
                                        value: _vm.formValidate.unitName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "unitName",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "formValidate.unitName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              _vm._b({}, "el-col", _vm.grid2, false),
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "平台商品分类：" } },
                                  [
                                    _c("el-cascader", {
                                      staticClass: "selWidth",
                                      attrs: {
                                        options: _vm.adminProductClassify,
                                        props: _vm.props1,
                                        "show-all-levels": false,
                                        disabled: _vm.isDisabled,
                                      },
                                      model: {
                                        value: _vm.formValidate.categoryId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "categoryId",
                                            $$v
                                          )
                                        },
                                        expression: "formValidate.categoryId",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              _vm._b({}, "el-col", _vm.grid2, false),
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "品牌：", prop: "brandId" },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "selWidth",
                                        attrs: {
                                          filterable: "",
                                          loading: _vm.loading,
                                          remote: "",
                                          disabled: _vm.isDisabled,
                                          placeholder: "请选择品牌",
                                        },
                                        model: {
                                          value: _vm.formValidate.brandId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formValidate,
                                              "brandId",
                                              $$v
                                            )
                                          },
                                          expression: "formValidate.brandId",
                                        },
                                      },
                                      _vm._l(_vm.brandList, function (user) {
                                        return _c("el-option", {
                                          key: user.id,
                                          attrs: {
                                            label: user.name,
                                            value: user.id,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              _vm._b(
                                { staticClass: "mb10" },
                                "el-col",
                                _vm.grid2,
                                false
                              ),
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "商品封面图：" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "upLoadPicBox",
                                        attrs: { disabled: _vm.isDisabled },
                                        on: {
                                          click: function ($event) {
                                            return _vm.modalPicTap("1")
                                          },
                                        },
                                      },
                                      [
                                        _vm.formValidate.image
                                          ? _c(
                                              "div",
                                              { staticClass: "pictrue" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: _vm.formValidate.image,
                                                  },
                                                }),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "upLoad" },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-camera cameraIconfont",
                                                }),
                                              ]
                                            ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              _vm._b({}, "el-col", _vm.grid2, false),
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "保障服务：" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "selWidth",
                                        attrs: {
                                          placeholder: "请选择保障服务",
                                          multiple: "",
                                          disabled: _vm.isDisabled,
                                        },
                                        model: {
                                          value:
                                            _vm.formValidate.guaranteeIdsList,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formValidate,
                                              "guaranteeIdsList",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formValidate.guaranteeIdsList",
                                        },
                                      },
                                      _vm._l(
                                        _vm.guaranteeList,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              value: item.id,
                                              label: item.name,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { staticClass: "mb10", attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "商品轮播图：" } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "acea-row" },
                                      [
                                        _vm._l(
                                          _vm.formValidate.sliderImages,
                                          function (item, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass: "pictrue",
                                              },
                                              [
                                                _c("img", {
                                                  attrs: { src: item },
                                                }),
                                              ]
                                            )
                                          }
                                        ),
                                        _vm._v(" "),
                                        _vm.formValidate.sliderImages.length <
                                          10 && !_vm.isDisabled
                                          ? _c(
                                              "div",
                                              { staticClass: "upLoadPicBox" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "upLoad" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-camera cameraIconfont",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              _vm._b(
                                { staticClass: "mb10" },
                                "el-col",
                                _vm.grid2,
                                false
                              ),
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "商品规格：",
                                      props: "specType",
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "spfont" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formValidate.specType
                                            ? "多规格"
                                            : "单规格"
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              _vm._b({}, "el-col", _vm.grid2, false),
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "佣金设置：",
                                      props: "specType",
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "spfont" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formValidate.isSub
                                            ? "单独设置"
                                            : "默认设置"
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { staticClass: "mt10", attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "商品属性：",
                                      props: "specType",
                                    },
                                  },
                                  [
                                    _vm.formValidate.specType === false
                                      ? [
                                          _c(
                                            "el-table",
                                            {
                                              staticClass: "tabNumWidth",
                                              attrs: {
                                                data: _vm.OneattrValue,
                                                border: "",
                                                size: "mini",
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  align: "center",
                                                  label: "图片",
                                                  width: "60",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "upLoadPicBox",
                                                            },
                                                            [
                                                              _vm.formValidate
                                                                .image
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "pictrue tabPic",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              src: scope
                                                                                .row
                                                                                .image,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "upLoad tabPic",
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "el-icon-camera cameraIconfont",
                                                                      }),
                                                                    ]
                                                                  ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1903352531
                                                ),
                                              }),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.attrValue,
                                                function (item, iii) {
                                                  return _c("el-table-column", {
                                                    key: iii,
                                                    attrs: {
                                                      label:
                                                        _vm.formThead[iii]
                                                          .title,
                                                      align: "center",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (scope) {
                                                            return [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    scope.row[
                                                                      iii
                                                                    ]
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  })
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.formValidate.attr.length > 0 &&
                                    _vm.formValidate.specType
                                      ? [
                                          _c(
                                            "el-table",
                                            {
                                              staticClass: "tabNumWidth",
                                              attrs: {
                                                data: _vm.ManyAttrValue,
                                                border: "",
                                                size: "mini",
                                              },
                                            },
                                            [
                                              _vm.manyTabDate
                                                ? _vm._l(
                                                    _vm.manyTabDate,
                                                    function (item, iii) {
                                                      return _c(
                                                        "el-table-column",
                                                        {
                                                          key: iii,
                                                          attrs: {
                                                            align: "center",
                                                            label:
                                                              _vm.manyTabTit[
                                                                iii
                                                              ].title,
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    _c("span", {
                                                                      staticClass:
                                                                        "priceBox",
                                                                      domProps:
                                                                        {
                                                                          textContent:
                                                                            _vm._s(
                                                                              scope
                                                                                .row[
                                                                                iii
                                                                              ]
                                                                            ),
                                                                        },
                                                                    }),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }
                                                      )
                                                    }
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  align: "center",
                                                  label: "图片",
                                                  width: "60",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "upLoadPicBox",
                                                            },
                                                            [
                                                              scope.row.image
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "pictrue tabPic",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              src: scope
                                                                                .row
                                                                                .image,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "upLoad tabPic",
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "el-icon-camera cameraIconfont",
                                                                      }),
                                                                    ]
                                                                  ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2631442157
                                                ),
                                              }),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.attrValue,
                                                function (item, iii) {
                                                  return _c("el-table-column", {
                                                    key: iii,
                                                    attrs: {
                                                      label:
                                                        _vm.formThead[iii]
                                                          .title,
                                                      align: "center",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (scope) {
                                                            return [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    scope.row[
                                                                      iii
                                                                    ]
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  })
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.currentTab === "1",
                                expression: "currentTab === '1'",
                              },
                            ],
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "商品详情：" } },
                                  [
                                    _c("span", {
                                      staticClass: "contentPic",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.formValidate.content || "无"
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.currentTab === "2",
                                expression: "currentTab === '2'",
                              },
                            ],
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-col",
                                  _vm._b({}, "el-col", _vm.grid, false),
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "排序：" } },
                                      [
                                        _c("span", { staticClass: "spfont" }, [
                                          _vm._v(_vm._s(_vm.formValidate.sort)),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "proCoupon",
                                    attrs: { label: "优惠券：" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "acea-row" },
                                      [
                                        _vm._l(
                                          _vm.formValidate.couponList,
                                          function (tag, index) {
                                            return _c(
                                              "el-tag",
                                              {
                                                key: index,
                                                staticClass: "mr10 mb10",
                                                attrs: {
                                                  closable: !_vm.isDisabled,
                                                  "disable-transitions": false,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(tag.name) +
                                                    "\n                  "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        _vm._v(" "),
                                        _vm.formValidate.couponList.length === 0
                                          ? _c("span", [_vm._v("无")])
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "demo-drawer__footer" }, [
            _vm.isAtud
              ? _c("div", { staticClass: "from-foot-btn drawer_fix" }, [
                  _c(
                    "div",
                    { staticClass: "acea-row justify-content" },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "debounceClick",
                              rawName: "v-debounceClick",
                              value: function () {
                                _vm.onSubmit("fail")
                              },
                              expression:
                                "\n              () => {\n                onSubmit('fail');\n              }\n            ",
                            },
                          ],
                          staticStyle: { "margin-left": "0" },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.loadingBtn ? "提交中 ..." : "审核拒绝")
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "debounceClick",
                              rawName: "v-debounceClick",
                              value: function () {
                                _vm.onSubmit("success")
                              },
                              expression:
                                "\n              () => {\n                onSubmit('success');\n              }\n            ",
                            },
                          ],
                          attrs: { type: "primary" },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.loadingBtn ? "提交中 ..." : "审核通过")
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }