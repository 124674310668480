var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configObj
    ? _c("div", { staticClass: "mobile-page" }, [
        _c("div", {
          staticClass: "box",
          style: _vm.boxStyle,
          domProps: { innerHTML: _vm._s(_vm.richText) },
        }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }