var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configObj
    ? _c("div", { staticClass: "search-box", style: _vm.boxStyle }, [
        _vm.configObj.logoConfig.isShow === 1 && _vm.logoUrl
          ? _c("img", { attrs: { src: _vm.logoUrl, alt: "" } })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "box", style: _vm.contentStyle }, [
          _c("i", { staticClass: "el-icon-search" }),
          _c("span", { staticClass: "ml20 line1" }, [
            _vm._v(_vm._s(_vm.hotWord)),
          ]),
        ]),
        _vm._v(" "),
        _vm._m(0),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "capsule" }, [
      _c("img", {
        attrs: { src: require("@/assets/imgs/capsule.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }