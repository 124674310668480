var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configObj
    ? _c("div", { staticClass: "mobile-page", style: _vm.boxStyle }, [
        _c(
          "div",
          { staticClass: "advert" },
          [
            _vm.style === 0
              ? _vm._l(_vm.picList, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "advertItem01 acea-row" },
                    [
                      item.image
                        ? _c("img", { attrs: { src: item.image } })
                        : _c("div", { staticClass: "empty-box" }, [
                            _c("span", { staticClass: "iconfont icon-tu" }),
                          ]),
                    ]
                  )
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.style === 1
              ? _c(
                  "div",
                  { staticClass: "advertItem02 acea-row" },
                  _vm._l(_vm.picList, function (item, index) {
                    return _c("div", { key: index, staticClass: "item" }, [
                      item.image
                        ? _c("img", { attrs: { src: item.image } })
                        : _c("div", { staticClass: "empty-box" }, [
                            _c("span", { staticClass: "iconfont icon-tu" }),
                          ]),
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.style === 2
              ? _c(
                  "div",
                  { staticClass: "advertItem02 advertItem03 acea-row" },
                  _vm._l(_vm.picList, function (item, index) {
                    return _c("div", { key: index, staticClass: "item" }, [
                      item.image
                        ? _c("img", { attrs: { src: item.image } })
                        : _c("div", { staticClass: "empty-box" }, [
                            _c("span", { staticClass: "iconfont icon-tu" }),
                          ]),
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.style === 3
              ? _c("div", { staticClass: "advertItem04 acea-row" }, [
                  _c("div", { staticClass: "item" }, [
                    _vm.picList[0].image
                      ? _c("img", { attrs: { src: _vm.picList[0].image } })
                      : _c("div", { staticClass: "empty-box" }, [
                          _c("span", { staticClass: "iconfont icon-tu" }),
                        ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item" }, [
                    _c("div", { staticClass: "pic" }, [
                      _vm.picList[1].image
                        ? _c("img", { attrs: { src: _vm.picList[1].image } })
                        : _c("div", { staticClass: "empty-box" }, [
                            _c("span", { staticClass: "iconfont icon-tu" }),
                          ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pic" }, [
                      _vm.picList[2].image
                        ? _c("img", { attrs: { src: _vm.picList[2].image } })
                        : _c("div", { staticClass: "empty-box" }, [
                            _c("span", { staticClass: "iconfont icon-tu" }),
                          ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.style === 4
              ? _c(
                  "div",
                  { staticClass: "advertItem02 advertItem05 acea-row" },
                  _vm._l(_vm.picList, function (item, index) {
                    return _c("div", { key: index, staticClass: "item" }, [
                      item.image
                        ? _c("img", { attrs: { src: item.image } })
                        : _c("div", { staticClass: "empty-box" }, [
                            _c("span", { staticClass: "iconfont icon-tu" }),
                          ]),
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.style === 5
              ? _c(
                  "div",
                  { staticClass: "advertItem06 acea-row" },
                  _vm._l(_vm.picList, function (item, index) {
                    return _c("div", { key: index, staticClass: "item" }, [
                      item.image
                        ? _c("img", { attrs: { src: item.image } })
                        : _c("div", { staticClass: "empty-box" }, [
                            _c("span", { staticClass: "iconfont icon-tu" }),
                          ]),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }