var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configObj
    ? _c("div", { staticClass: "page-container" }, [
        _c("div", { staticClass: "bg-img" }, [
          _c("img", { attrs: { src: _vm.imgSrc, alt: "" } }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "search-box" }, [
          _vm.configObj.logoConfig.isShow === 1 && _vm.logoUrl
            ? _c("img", {
                attrs: { src: _vm.logoUrl, alt: "", mode: "widthFix" },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "box line1", style: _vm.contentStyle }, [
            _c("i", { staticClass: "el-icon-search" }),
            _vm._v(_vm._s(_vm.hotWord)),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "menus" },
          [
            _vm._l(_vm.list, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "item line1",
                  class: { on: _vm.curIndex === index },
                  style: {
                    color:
                      _vm.curIndex === index
                        ? _vm.configObj.checkColor.color[0].item
                        : _vm.textColor,
                  },
                },
                [
                  _vm._v("\n      " + _vm._s(item.title) + " "),
                  _c("span", { style: _vm.checkColor }),
                ]
              )
            }),
            _vm._v(" "),
            _c("div", { staticClass: "category" }, [
              _c("span", {
                staticClass: "iconfont icon-shouye_xidingfenlei",
                style: _vm.checkColor,
              }),
            ]),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "banner" }, [
          _vm.imgSrc
            ? _c("img", {
                style: _vm.contentStyleBanner,
                attrs: { src: _vm.imgSrc, alt: "" },
              })
            : _c(
                "div",
                { staticClass: "empty-box", style: _vm.contentStyleBanner },
                [_c("span", { staticClass: "iconfont iconfont icon-tu" })]
              ),
          _vm._v(" "),
          _c("div", [
            _vm.docType === 0
              ? _c("div", { staticClass: "dot", style: _vm.dotStyle }, [
                  _c("div", {
                    staticClass: "dot-item",
                    style: { background: _vm.configObj.docColor.color[0].item },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "dot-item" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "dot-item" }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.docType === 1
              ? _c(
                  "div",
                  { staticClass: "dot line-dot", style: _vm.dotStyle },
                  [
                    _c("div", {
                      staticClass: "line_dot-item",
                      style: {
                        background: _vm.configObj.docColor.color[0].item,
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "line_dot-item" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "line_dot-item" }),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }