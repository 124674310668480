var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configData.isShow === 1
    ? _c("div", { staticClass: "mb20 borderPadding" }, [
        _c("div", { staticClass: "c_row-item" }, [
          _vm.configData.title
            ? _c("div", { staticClass: "label labelwidth" }, [
                _vm._v("\n      " + _vm._s(_vm.configData.title) + "\n    "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "slider-box ml22" },
            [
              _c("el-cascader", {
                staticClass: "selWidth",
                attrs: {
                  "show-all-levels": false,
                  options: _vm.merchantList,
                  props: _vm.merProps,
                  filterable: "",
                  clearable: "",
                },
                on: { change: _vm.onChangeMerId },
                model: {
                  value: _vm.merIds,
                  callback: function ($$v) {
                    _vm.merIds = $$v
                  },
                  expression: "merIds",
                },
              }),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }