var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configData && _vm.configData.isShow === 1
    ? _c("div", { staticClass: "borderPadding" }, [
        _c("div", { staticClass: "c_row-item" }, [
          _vm.configData.title
            ? _c("div", { staticClass: "label labelwidth" }, [
                _vm._v("\n      " + _vm._s(_vm.configData.title) + "\n    "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "slider-box ml22" },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { size: "small", multiple: _vm.configData.isMultiple },
                  on: { change: _vm.sliderChange },
                  model: {
                    value: _vm.configData.activeValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.configData, "activeValue", $$v)
                    },
                    expression: "configData.activeValue",
                  },
                },
                _vm._l(_vm.configData.list, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: {
                      value: item.id,
                      label: item.name,
                      disabled: !item.isShow && !item.status,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }