var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configData
    ? _c("div", { staticClass: "borderPadding" }, [
        _c("div", { staticClass: "c_row-item" }, [
          _vm.configData.title
            ? _c("div", { staticClass: "label labelwidth" }, [
                _vm._v("\n      " + _vm._s(_vm.configData.title) + "\n    "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "slider-box ml22" },
            [
              _c("el-slider", {
                attrs: {
                  "show-input": "",
                  min: _vm.configData.min,
                  max: _vm.configData.max,
                },
                on: {
                  change: function ($event) {
                    return _vm.sliderChange($event)
                  },
                },
                model: {
                  value: _vm.configData.val,
                  callback: function ($$v) {
                    _vm.$set(_vm.configData, "val", $$v)
                  },
                  expression: "configData.val",
                },
              }),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }