var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configObj
    ? _c("div", { style: _vm.boxStyle }, [
        _vm.isOne
          ? _c("div", { staticClass: "mobile-page" }, [
              _c(
                "div",
                { staticClass: "list_menu", style: _vm.gridColumns },
                [
                  _vm._l(_vm.vuexMenu, function (item, index) {
                    return [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.status,
                              expression: "item.status",
                            },
                          ],
                          staticClass: "item",
                        },
                        [
                          _c("div", { staticClass: "img-box" }, [
                            item.img
                              ? _c("img", {
                                  style: _vm.contentStyle,
                                  attrs: { src: item.img, alt: "" },
                                })
                              : _c(
                                  "div",
                                  {
                                    staticClass: "empty-box on",
                                    style: _vm.contentStyle,
                                  },
                                  [
                                    _c("span", {
                                      staticClass:
                                        "iconfont-diy iconfont icon-tu",
                                    }),
                                  ]
                                ),
                          ]),
                          _vm._v(" "),
                          _c("p", { style: _vm.titleColor }, [
                            _vm._v(_vm._s(item.info[0].value)),
                          ]),
                        ]
                      ),
                    ]
                  }),
                ],
                2
              ),
            ])
          : _c("div", { staticClass: "mobile-page" }, [
              _c(
                "div",
                { staticClass: "home_menu", style: _vm.gridColumns },
                [
                  _vm._l(_vm.vuexMenu, function (item, index) {
                    return [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.status,
                              expression: "item.status",
                            },
                          ],
                          staticClass: "menu-item",
                        },
                        [
                          _c("div", { staticClass: "img-box" }, [
                            item.img
                              ? _c("img", {
                                  style: _vm.contentStyle,
                                  attrs: { src: item.img, alt: "" },
                                })
                              : _c(
                                  "div",
                                  {
                                    staticClass: "empty-box on",
                                    style: _vm.contentStyle,
                                  },
                                  [
                                    _c("span", {
                                      staticClass:
                                        "iconfont-diy iconfont icon-tu",
                                    }),
                                  ]
                                ),
                          ]),
                          _vm._v(" "),
                          _c("p", { style: _vm.titleColor }, [
                            _vm._v(_vm._s(item.info[0].value)),
                          ]),
                        ]
                      ),
                    ]
                  }),
                ],
                2
              ),
            ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }