var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c("div", { staticClass: "auth acea-row row-between-wrapper" }, [
          _c("div", { staticClass: "acea-row row-middle" }, [
            _vm.status === 1
              ? _c("div", { staticClass: "text" }, [
                  _c("div", [_vm._v("商业授权")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "code" }, [
                    _vm._v("授权码：" + _vm._s(_vm.authCode)),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.status === -1
              ? _c("div", { staticClass: "text" }, [_vm._v("请申请授权")])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm.status === 1
                ? _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.toCrmeb()
                        },
                      },
                    },
                    [_vm._v("进入官网")]
                  )
                : _vm.status === -1
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.applyAuth("java_mer")
                        },
                      },
                    },
                    [_vm._v("申请授权")]
                  )
                : _vm.status === 2
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.applyAuth("java_mer")
                        },
                      },
                    },
                    [_vm._v("重新申请")]
                  )
                : _vm.status === 0
                ? _c("el-button", { staticClass: "grey" }, [_vm._v("审核中")])
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      !_vm.copyright && _vm.status == 1
        ? _c(
            "el-card",
            { staticClass: "box-card", staticStyle: { "margin-top": "15px" } },
            [
              _c("div", { staticClass: "auth acea-row row-between-wrapper" }, [
                _c("div", { staticClass: "acea-row row-middle" }, [
                  _c("i", { staticClass: "el-icon-s-help iconIos blue" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "text" }, [
                    _c("div", [_vm._v("去版权服务")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "code" }, [
                      _vm._v("购买之后可以设置"),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.applyAuth("copyright")
                          },
                        },
                      },
                      [_vm._v("去版权")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.copyright
        ? _c(
            "el-card",
            { staticStyle: { "margin-top": "15px" } },
            [
              _c(
                "el-form",
                {
                  ref: "copyForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.copyForm,
                    rules: _vm.rules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "修改授权信息", prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.copyForm.companyName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.copyForm,
                              "companyName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "copyForm.companyName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "上传授权图片", prop: "region" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "authorized",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap()
                            },
                          },
                        },
                        [
                          _vm.copyForm.companyImage
                            ? _c("div", { staticClass: "uploadPictrue" }, [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "lazy",
                                      rawName: "v-lazy",
                                      value: _vm.copyForm.companyImage,
                                      expression: "copyForm.companyImage",
                                    },
                                  ],
                                }),
                              ])
                            : _c("div", { staticClass: "upload" }, [
                                _c("div", { staticClass: "iconfont" }, [
                                  _vm._v("+"),
                                ]),
                              ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "prompt" }, [
                        _vm._v("建议尺寸：宽290px*高100px"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["platform:copyright:update:company:info"],
                          expression:
                            "['platform:copyright:update:company:info']",
                        },
                      ],
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.saveCopyRight("copyForm")
                            },
                          },
                        },
                        [_vm._v("保存")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isTemplate
        ? _c(
            "el-dialog",
            {
              staticClass: "mapBox",
              staticStyle: { padding: "0" },
              attrs: {
                visible: _vm.isTemplate,
                "close-on-click-modal": false,
                "before-close": _vm.handleClose,
                width: "440px",
                title: _vm.title,
                "close-on-click-modal": "",
                "custom-class": "dialog-scustom",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.isTemplate = $event
                },
              },
              model: {
                value: _vm.isTemplate,
                callback: function ($$v) {
                  _vm.isTemplate = $$v
                },
                expression: "isTemplate",
              },
            },
            [
              _c("iframe", {
                staticStyle: { width: "400px", height: "600px" },
                attrs: { src: _vm.iframeUrl, frameborder: "0" },
              }),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }