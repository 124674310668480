var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "acea-row title-box mb20" }, [
    _c("div", { staticClass: "title-bar mr20" }, [
      _vm._v(_vm._s(_vm.configData.tabTitle)),
    ]),
    _vm._v(" "),
    _vm.configData.info
      ? _c("div", { staticClass: "title-tips" }, [
          _vm._v(_vm._s(_vm.configData.info)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }