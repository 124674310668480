var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "selfForm",
          attrs: { model: _vm.selfForm, "label-width": "120px" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "排序",
                prop: "sort",
                rules: [
                  {
                    required: true,
                    message: "排序不能为空",
                    trigger: ["change"],
                  },
                ],
              },
            },
            [
              _c("el-input-number", {
                attrs: { min: 1 },
                model: {
                  value: _vm.selfForm.sort,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.selfForm,
                      "sort",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "selfForm.sort",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "状态",
                prop: "status",
                rules: [
                  {
                    required: true,
                    message: "正确操作状态",
                    trigger: ["change"],
                  },
                ],
              },
            },
            [
              _c("el-switch", {
                model: {
                  value: _vm.selfForm.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.selfForm, "status", $$v)
                  },
                  expression: "selfForm.status",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.formConf.fields.length > 0
        ? _c("parser", {
            attrs: {
              "is-edit": _vm.isCreate === 1,
              "form-conf": _vm.formConf,
              "form-edit-data": _vm.editData,
            },
            on: { submit: _vm.handlerSubmit },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }