var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "borderPadding mb20" }, [
    _vm.configData && _vm.configData.isShow === 1
      ? _c("div", { staticClass: "c_row-item" }, [
          _c("div", { staticClass: "label labelwidth" }, [
            _vm._v("\n      " + _vm._s(_vm.configData.title) + "\n    "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "slider-box ml22" },
            [
              _c("el-cascader", {
                staticStyle: { width: "100%" },
                attrs: {
                  options: _vm.configData.list,
                  placeholder: "请选择" + _vm.configData.title,
                  filterable: "",
                  props: _vm.props,
                  clearable: "",
                  "show-all-levels": false,
                },
                on: { change: _vm.sliderChange },
                model: {
                  value: _vm.configData.activeValue,
                  callback: function ($$v) {
                    _vm.$set(_vm.configData, "activeValue", $$v)
                  },
                  expression: "configData.activeValue",
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }