var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "goods_detail" }, [
    _c(
      "div",
      { staticClass: "goods_detail_wrapper", class: _vm.url ? "on" : "" },
      [
        _vm.url
          ? _c("iframe", {
              staticStyle: { width: "100%", height: "600px" },
              attrs: { src: _vm.url, frameborder: "0" },
            })
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }