var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("h3", { staticClass: "title" }, [_vm._v("等级规则说明")]),
          _vm._v(" "),
          _c("Tinymce", {
            key: _vm.keyIndex,
            staticClass: "mb20",
            model: {
              value: _vm.formValidate.rule,
              callback: function ($$v) {
                _vm.$set(_vm.formValidate, "rule", $$v)
              },
              expression: "formValidate.rule",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "submission",
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.previewProtol },
            },
            [_vm._v("预览")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["platform:system:user:level:rule:update"],
                  expression: "['platform:system:user:level:rule:update']",
                },
                {
                  name: "debounceClick",
                  rawName: "v-debounceClick",
                  value: function () {
                    _vm.handleSubmit("formValidate")
                  },
                  expression:
                    "\n        () => {\n          handleSubmit('formValidate');\n        }\n      ",
                },
              ],
              staticClass: "submission",
              attrs: { type: "primary", size: "small" },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "Box" },
        [
          _vm.modals
            ? _c(
                "el-dialog",
                {
                  staticClass: "addDia",
                  attrs: {
                    visible: _vm.modals,
                    title: "",
                    height: "30%",
                    "custom-class": "dialog-scustom",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.modals = $event
                    },
                  },
                },
                [
                  _c("div", { staticClass: "agreement" }, [
                    _c("h3", [_vm._v("等级规则说明")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.formValidate.rule) },
                      }),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }