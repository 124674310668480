"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var path = require('path');
var files = require.context('./', false, /\.vue$/);
var modules = {};
files.keys().forEach(function (key) {
  var name = path.basename(key, '.vue');
  modules[name] = files(key).default || files(key);
});
var _default = exports.default = modules;