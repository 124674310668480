var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hot_imgs borderPadding" },
    [
      _c(
        "div",
        { staticClass: "list-box" },
        [
          _c(
            "draggable",
            {
              staticClass: "dragArea list-group",
              attrs: {
                list: _vm.configData.list,
                group: "peoples",
                handle: ".move-icon",
              },
            },
            _vm._l(_vm.configData.list, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "acea-row row-middle" },
                [
                  _c("div", { staticClass: "move-icon" }, [
                    _c("i", {
                      staticClass: "iconfont icon-tuozhuaidian",
                      staticStyle: { "font-size": "20px", color: "#dddddd" },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "item",
                      class: _vm.activeIndex === index ? "checked" : "",
                      on: {
                        click: function ($event) {
                          return _vm.onChecked(item, index)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "info" }, [
                        _c("div", { staticClass: "info-item" }, [
                          _c("span", [_vm._v(_vm._s(item.val) + " ")]),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "delete",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.bindDelete(index)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-error",
                        staticStyle: { "font-size": "20px" },
                      }),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.configData.list
        ? [
            _c(
              "div",
              { staticClass: "add-btn" },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { width: "100%", height: "35px" },
                    attrs: { size: "small", icon: "el-icon-plus", plain: "" },
                    on: { click: _vm.addBox },
                  },
                  [_vm._v("添加选项卡")]
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "title-bar mt10" }, [_vm._v("选项卡内容")]),
      _vm._v(" "),
      _c("div", { staticClass: "c_row-item" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "slider-box" },
          [
            _c("el-input", {
              staticStyle: { "text-align": "right" },
              attrs: {
                placeholder: "请输入选项名称（最多10个字）",
                step: 1,
                maxlength: "10",
                minlength: "1",
              },
              on: { input: _vm.onInput },
              model: {
                value: _vm.tabVal,
                callback: function ($$v) {
                  _vm.tabVal = $$v
                },
                expression: "tabVal",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "label" }, [
      _c("span", [_vm._v("选项名称")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }