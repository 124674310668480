var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "infoBox" },
    [
      _vm.dialogVisible
        ? _c(
            "el-drawer",
            {
              staticClass: "infoBox",
              attrs: {
                visible: _vm.dialogVisible,
                title: _vm.isAtud ? "商品审核" : "商品详情",
                direction: _vm.direction,
                "append-to-body": true,
                "custom-class": "demo-drawer",
                size: "1000px",
                wrapperClosable: _vm.isAtud ? false : true,
                "modal-append-to-body": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c("div", { staticClass: "demo-drawer__content" }, [
                _vm.formValidate
                  ? _c(
                      "div",
                      { staticClass: "divBox" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _c(
                              "el-tabs",
                              {
                                model: {
                                  value: _vm.currentTab,
                                  callback: function ($$v) {
                                    _vm.currentTab = $$v
                                  },
                                  expression: "currentTab",
                                },
                              },
                              [
                                _c("el-tab-pane", {
                                  attrs: { label: "商品信息", name: "0" },
                                }),
                                _vm._v(" "),
                                _c("el-tab-pane", {
                                  attrs: { label: "商品详情", name: "1" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading",
                              },
                            ],
                            ref: "formValidate",
                            staticClass: "formValidate",
                            attrs: {
                              model: _vm.formValidate,
                              "label-width": "111px",
                            },
                            nativeOn: {
                              submit: function ($event) {
                                $event.preventDefault()
                              },
                            },
                          },
                          [
                            _c(
                              "el-row",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.currentTab === "0",
                                    expression: "currentTab === '0'",
                                  },
                                ],
                                attrs: { gutter: 24 },
                              },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "商品名称：",
                                          prop: "title",
                                        },
                                      },
                                      [
                                        _c("span", { staticClass: "spfont" }, [
                                          _vm._v(
                                            _vm._s(_vm.formValidate.title)
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  _vm._b({}, "el-col", _vm.grid2, false),
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "单位：",
                                          prop: "unitName",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入单位",
                                            readonly: _vm.isDisabled,
                                          },
                                          model: {
                                            value: _vm.formValidate.unitName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formValidate,
                                                "unitName",
                                                $$v
                                              )
                                            },
                                            expression: "formValidate.unitName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  _vm._b({}, "el-col", _vm.grid2, false),
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "微信商品类目：",
                                          prop: "thirdCatIdList",
                                        },
                                      },
                                      _vm._l(
                                        _vm.formValidate.thirdCatIdList,
                                        function (item, i) {
                                          return _c("span", [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(item) +
                                                " "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      i <
                                                      _vm.formValidate
                                                        .thirdCatIdList.length -
                                                        1,
                                                    expression:
                                                      "i < formValidate.thirdCatIdList.length - 1",
                                                  },
                                                ],
                                              },
                                              [_vm._v("/")]
                                            ),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "商品轮播图：" } },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "acea-row" },
                                          _vm._l(
                                            _vm.formValidate.images,
                                            function (item, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass: "pictrue",
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: { src: item },
                                                  }),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.productQualificationType > 0
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "商品资质图：" } },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "acea-row" },
                                              [
                                                _vm._l(
                                                  _vm.formValidate
                                                    .qualificationPicsList,
                                                  function (item, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticClass: "pictrue",
                                                        attrs: {
                                                          draggable: "true",
                                                        },
                                                        on: {
                                                          dragstart: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleDragStart(
                                                              $event,
                                                              item,
                                                              "qualificationPics"
                                                            )
                                                          },
                                                          dragover: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.handleDragOver(
                                                              $event,
                                                              item,
                                                              "qualificationPics"
                                                            )
                                                          },
                                                          dragenter: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleDragEnter(
                                                              $event,
                                                              item,
                                                              "qualificationPics"
                                                            )
                                                          },
                                                          dragend: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleDragEnd(
                                                              $event,
                                                              item,
                                                              "qualificationPics"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: { src: item },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-error btndel",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleRemove(
                                                                index,
                                                                "qualificationPics"
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _vm._v(" "),
                                                _vm.formValidate
                                                  .qualificationPicsList
                                                  .length < 5
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "upLoadPicBox",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.modalPicTap(
                                                              "3"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "upLoad",
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-camera cameraIconfont",
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "fontColor3" },
                                              [
                                                _vm._v(
                                                  "\n                  资质类型说明：" +
                                                    _vm._s(
                                                      _vm.productQualification
                                                    ) +
                                                    "。" +
                                                    _vm._s(
                                                      _vm.productQualificationType ===
                                                        1
                                                        ? "必填项！"
                                                        : "选填项！"
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  _vm._b({}, "el-col", _vm.grid2, false),
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "商品规格：",
                                          props: "specType",
                                        },
                                      },
                                      [
                                        _c("span", { staticClass: "spfont" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formValidate.specType
                                                ? "多规格"
                                                : "单规格"
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "mt10",
                                    attrs: {
                                      xl: 24,
                                      lg: 24,
                                      md: 24,
                                      sm: 24,
                                      xs: 24,
                                    },
                                  },
                                  [
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          staticClass: "tabNumWidth",
                                          attrs: {
                                            data: _vm.ManyAttrValue,
                                            border: "",
                                            size: "mini",
                                          },
                                        },
                                        [
                                          _vm.manyTabDate
                                            ? _vm._l(
                                                _vm.manyTabDate,
                                                function (item, iii) {
                                                  return _c("el-table-column", {
                                                    key: iii,
                                                    attrs: {
                                                      align: "center",
                                                      label:
                                                        _vm.manyTabTit[iii]
                                                          .title,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (scope) {
                                                            return [
                                                              _c("span", {
                                                                staticClass:
                                                                  "priceBox",
                                                                domProps: {
                                                                  textContent:
                                                                    _vm._s(
                                                                      scope.row[
                                                                        iii
                                                                      ]
                                                                    ),
                                                                },
                                                              }),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  })
                                                }
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              label: "图片",
                                              width: "60",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoadPicBox",
                                                        },
                                                        [
                                                          scope.row.image
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pictrue tabPic",
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src: scope
                                                                        .row
                                                                        .image,
                                                                    },
                                                                  }),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1312690362
                                            ),
                                          }),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.attrValue,
                                            function (item, iii) {
                                              return _c("el-table-column", {
                                                key: iii,
                                                attrs: {
                                                  label:
                                                    _vm.formThead[iii].title,
                                                  align: "center",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.row[iii]
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              })
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.currentTab === "1" && !_vm.isDisabled,
                                    expression:
                                      "currentTab === '1' && !isDisabled",
                                  },
                                ],
                              },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "商品详情：" } },
                                      [
                                        _c("div", {
                                          staticClass: "contentPic",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.formValidate.content
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.currentTab === "1" && _vm.isDisabled,
                                    expression:
                                      "currentTab === '1' && isDisabled",
                                  },
                                ],
                              },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "商品详情：" } },
                                      [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.formValidate.descInfo || "无"
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.isAtud
                ? _c(
                    "div",
                    { staticClass: "from-foot-btn btn-shadow" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "ruleForm",
                          staticClass: "demo-ruleForm",
                          attrs: {
                            model: _vm.ruleForm,
                            rules: _vm.rules,
                            "label-width": "80px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "审核状态",
                                prop: "platformEditStatus",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.ruleForm.platformEditStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "platformEditStatus",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.platformEditStatus",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 4 } }, [
                                    _vm._v("通过"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 3 } }, [
                                    _vm._v("拒绝"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.ruleForm.platformEditStatus === 3
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "原因",
                                    prop: "platformStatusReason",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      placeholder: "请输入原因",
                                    },
                                    model: {
                                      value: _vm.ruleForm.platformStatusReason,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "platformStatusReason",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ruleForm.platformStatusReason",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            [
                              _c("el-button", { on: { click: _vm.close } }, [
                                _vm._v("取 消"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onSubmit("ruleForm")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.loadingBtn ? "提交中 ..." : "确 定"
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }