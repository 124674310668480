var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "acea-row row-between borderPadding mb20" }, [
    _vm.configData.title
      ? _c("div", { staticClass: "title-tips labelwidth" }, [
          _c("span", [_vm._v(_vm._s(_vm.configData.title))]),
          _vm._v(
            _vm._s(_vm.configData.list[_vm.configData.tabVal].val) + "\n  "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "radio-box labelml",
        class: { on: _vm.configData.type == 1 },
      },
      [
        _c(
          "el-radio-group",
          {
            attrs: { size: "large" },
            on: {
              change: function ($event) {
                return _vm.radioChange($event)
              },
            },
            model: {
              value: _vm.configData.tabVal,
              callback: function ($$v) {
                _vm.$set(_vm.configData, "tabVal", $$v)
              },
              expression: "configData.tabVal",
            },
          },
          _vm._l(_vm.configData.list, function (item, index) {
            return _c(
              "el-radio-button",
              { key: index, attrs: { label: index } },
              [
                item.icon
                  ? _c("span", { staticClass: "iconfont", class: item.icon })
                  : _c("span", [_vm._v(_vm._s(item.name))]),
              ]
            )
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }