var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configObj
    ? _c("div", { staticClass: "news-box", style: _vm.boxStyle }, [
        _c("div", { staticClass: "item", style: _vm.textColor }, [
          _c("div", { staticClass: "img-box" }, [
            _c("img", { attrs: { src: _vm.imgUrl, alt: "" } }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "right-box", style: _vm.textPosition }, [
            _vm._v(_vm._s(_vm.list[0].chiild[0].val)),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }