var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _vm.checkPermi(["platform:system:form:info"])
            ? _c(
                "el-tabs",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  on: { "tab-click": _vm.handleTabClick },
                  model: {
                    value: _vm.activeNamel1,
                    callback: function ($$v) {
                      _vm.activeNamel1 = $$v
                    },
                    expression: "activeNamel1",
                  },
                },
                _vm._l(_vm.treeList, function (tab, index) {
                  return _c(
                    "el-tab-pane",
                    {
                      key: index,
                      attrs: { label: tab.name, name: tab.id.toString() },
                    },
                    [
                      [
                        tab.child && tab.child.length > 0
                          ? _c(
                              "el-tabs",
                              {
                                attrs: { type: "border-card" },
                                on: { "tab-click": _vm.handleItemTabClick },
                                model: {
                                  value: _vm.activeNamel2,
                                  callback: function ($$v) {
                                    _vm.activeNamel2 = $$v
                                  },
                                  expression: "activeNamel2",
                                },
                              },
                              _vm._l(tab.child, function (tabItem, itemIndex) {
                                return _c(
                                  "el-tab-pane",
                                  {
                                    key: itemIndex,
                                    attrs: {
                                      label: tabItem.name,
                                      name: tabItem.extra,
                                    },
                                  },
                                  [
                                    _vm.formConfChild.render
                                      ? _c("parser", {
                                          attrs: {
                                            "is-edit": _vm.formConfChild.isEdit,
                                            "form-conf":
                                              _vm.formConfChild.content,
                                            "form-edit-data":
                                              _vm.currentEditData,
                                          },
                                          on: { submit: _vm.handlerSubmit },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          : _c(
                              "span",
                              [
                                _vm.formConfChild.render
                                  ? _c("parser", {
                                      attrs: {
                                        "is-edit": _vm.formConfChild.isEdit,
                                        "form-conf": _vm.formConfChild.content,
                                        "form-edit-data": _vm.currentEditData,
                                      },
                                      on: { submit: _vm.handlerSubmit },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                      ],
                    ],
                    2
                  )
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }