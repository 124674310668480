var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingFrom,
              expression: "loadingFrom",
            },
          ],
          ref: "dataForm",
          attrs: {
            inline: true,
            model: _vm.dataForm,
            "label-width": "100px",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { staticClass: "el-alert", attrs: { label: "" } },
            [
              _c("el-alert", {
                attrs: {
                  title:
                    "商户登录账号为手机号，初始密码为000000，可从个人中心修改密码",
                  type: "warning",
                  effect: "dark",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "lang", attrs: { label: "商户名称", prop: "name" } },
            [
              _c("el-input", {
                attrs: {
                  maxlength: _vm.isCn ? "8" : "16",
                  disabled: _vm.isDisabled,
                  placeholder: "请输入商户名称",
                },
                model: {
                  value: _vm.dataForm.name,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.dataForm,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "dataForm.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "inline",
              attrs: { label: "商户手机号", prop: "phone" },
            },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.isDisabled || _vm.merId > 0,
                  placeholder: "请输入商户手机号",
                },
                model: {
                  value: _vm.dataForm.phone,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.dataForm,
                      "phone",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "dataForm.phone",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "inline",
              attrs: { label: "商户姓名", prop: "realName" },
            },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.isDisabled,
                  placeholder: "请输入商户姓名",
                },
                model: {
                  value: _vm.dataForm.realName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.dataForm,
                      "realName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "dataForm.realName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "inline",
              attrs: { label: "商户分类", prop: "categoryId" },
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择商户分类",
                    disabled: _vm.isDisabled,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.onChange(_vm.dataForm.categoryId)
                    },
                  },
                  model: {
                    value: _vm.dataForm.categoryId,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "categoryId", $$v)
                    },
                    expression: "dataForm.categoryId",
                  },
                },
                _vm._l(_vm.merchantClassify, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "inline",
              attrs: { label: "手续费(%)", prop: "handlingFee" },
            },
            [
              _c("el-input-number", {
                attrs: { disabled: _vm.isDisabled, min: 0, precision: 2 },
                model: {
                  value: _vm.dataForm.handlingFee,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.dataForm,
                      "handlingFee",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "dataForm.handlingFee",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "inline",
              attrs: { label: "店铺类型", prop: "typeId" },
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择店铺类型",
                    disabled: _vm.isDisabled,
                  },
                  model: {
                    value: _vm.dataForm.typeId,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "typeId", $$v)
                    },
                    expression: "dataForm.typeId",
                  },
                },
                _vm._l(_vm.merchantType, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "inline", attrs: { label: "排序", prop: "sort" } },
            [
              _c("el-input-number", {
                attrs: {
                  disabled: _vm.isDisabled,
                  min: _vm.$constants.NUM_Range.min,
                  max: _vm.$constants.NUM_Range.max,
                },
                model: {
                  value: _vm.dataForm.sort,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.dataForm,
                      "sort",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "dataForm.sort",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "lang",
              attrs: { label: "商户关键字", prop: "labelarr" },
            },
            [
              _c("Keyword", {
                attrs: { isDisabled: _vm.isDisabled, labelarr: _vm.labelarr },
                on: { getLabelarr: _vm.getLabelarr },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "lang",
              attrs: { label: "资质图片：", prop: "sliderImages" },
            },
            [
              _c(
                "div",
                { staticClass: "acea-row" },
                [
                  _vm._l(_vm.dataForm.sliderImages, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "pictrue",
                        attrs: { draggable: "true" },
                        on: {
                          dragstart: function ($event) {
                            return _vm.handleDragStart($event, item)
                          },
                          dragover: function ($event) {
                            $event.preventDefault()
                            return _vm.handleDragOver($event, item)
                          },
                          dragenter: function ($event) {
                            return _vm.handleDragEnter($event, item)
                          },
                          dragend: function ($event) {
                            return _vm.handleDragEnd($event, item)
                          },
                        },
                      },
                      [
                        _c("img", { attrs: { src: item } }),
                        _vm._v(" "),
                        !_vm.isDisabled
                          ? _c("i", {
                              staticClass: "el-icon-error btndel",
                              on: {
                                click: function ($event) {
                                  return _vm.handleRemove(index)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm.dataForm.sliderImages.length < 10 && !_vm.isDisabled
                    ? _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("2")
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _vm.dataForm.addressDetail
            ? _c(
                "el-form-item",
                { staticClass: "lang", attrs: { label: "商户地址：" } },
                [_c("span", [_vm._v(_vm._s(_vm.dataForm.addressDetail))])]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "lang", attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.isDisabled,
                  type: "textarea",
                  placeholder: "请输入备注",
                },
                model: {
                  value: _vm.dataForm.remark,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.dataForm,
                      "remark",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "dataForm.remark",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.merId > 0
            ? _c(
                "el-form-item",
                { staticClass: "inline", attrs: { label: "星级评分" } },
                [
                  _c("el-rate", {
                    staticStyle: { "margin-top": "8px" },
                    attrs: { disabled: _vm.merId > 0 && _vm.isDisabled },
                    model: {
                      value: _vm.dataForm.starLevel,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "starLevel", $$v)
                      },
                      expression: "dataForm.starLevel",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.dataForm.isSwitch
            ? _c(
                "el-form-item",
                { staticClass: "inline", attrs: { label: "是否开启" } },
                [
                  _c("el-switch", {
                    attrs: {
                      disabled: _vm.isDisabled,
                      "active-value": true,
                      "inactive-value": false,
                      "active-text": "显示",
                      "inactive-text": "隐藏",
                    },
                    model: {
                      value: _vm.dataForm.isSwitch,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "isSwitch", $$v)
                      },
                      expression: "dataForm.isSwitch",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "inline", attrs: { label: "是否推荐" } },
            [
              _c("el-switch", {
                attrs: {
                  disabled: _vm.isDisabled,
                  "active-value": true,
                  "inactive-value": false,
                  "active-text": "推荐",
                  "inactive-text": "不推荐",
                },
                model: {
                  value: _vm.dataForm.isRecommend,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "isRecommend", $$v)
                  },
                  expression: "dataForm.isRecommend",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "inline", attrs: { label: "是否自营" } },
            [
              _c("el-switch", {
                attrs: {
                  disabled: _vm.isDisabled,
                  "active-value": true,
                  "inactive-value": false,
                  "active-text": "自营",
                  "inactive-text": "非自营",
                },
                model: {
                  value: _vm.dataForm.isSelf,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "isSelf", $$v)
                  },
                  expression: "dataForm.isSelf",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "inline", attrs: { label: "商品审核" } },
            [
              _c("el-switch", {
                attrs: {
                  disabled: _vm.isDisabled,
                  "active-value": true,
                  "inactive-value": false,
                  "active-text": "开启",
                  "inactive-text": "关闭",
                },
                model: {
                  value: _vm.dataForm.productSwitch,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "productSwitch", $$v)
                  },
                  expression: "dataForm.productSwitch",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isDisabled
        ? _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handleClose("dataForm")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: [
                        "platform:merchant:add",
                        "platform:merchant:update",
                      ],
                      expression:
                        "['platform:merchant:add', 'platform:merchant:update']",
                    },
                  ],
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.onsubmit("dataForm")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }