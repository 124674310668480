var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-alert",
            {
              staticClass: "mb35",
              attrs: { type: "warning", closable: false, "show-icon": "" },
            },
            [
              _vm._t("title", [
                _c(
                  "div",
                  { staticClass: "acea-row" },
                  [
                    _c("div", [_vm._v("社区状态在页面设计中进行开启/关闭；")]),
                    _vm._v(" "),
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.handlerToLink },
                      },
                      [_vm._v("立即前往")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "form-data",
            },
            [
              !_vm.loading && _vm.checkPermi(["platform:community:get:config"])
                ? _c("z-b-parser", {
                    attrs: {
                      "is-create": 1,
                      "form-conf": _vm.formConf,
                      "edit-data": _vm.editData,
                      "form-name": _vm.formName,
                      "key-num": _vm.keyNum,
                    },
                    on: { submit: _vm.handlerSubmit },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }