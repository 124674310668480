"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _community = require("@/api/community");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'communityConfig',
  data: function data() {
    return {
      formConf: {
        fields: []
      },
      loading: false,
      keyNum: 0,
      editData: {},
      formName: 'communityConfig'
    };
  },
  created: function created() {
    //this.keyNum += 1
    if ((0, _permission.checkPermi)(['platform:community:get:config'])) this.getConfigInfo();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    //立即前往
    handlerToLink: function handlerToLink() {
      var _this$$router$resolve = this.$router.resolve({
          path: '/page/design/viewDesign'
        }),
        href = _this$$router$resolve.href;
      window.open(href);
    },
    handlerSubmit: function handlerSubmit(formValue) {
      var _this = this;
      if ((0, _permission.checkPermi)(['platform:community:update:config'])) {
        (0, _community.communityConfigUpdateApi)(formValue).then(function (res) {
          _this.$message.success('操作成功');
          _this.getConfigInfo();
        }).catch(function () {
          _this.loading = false;
        });
      } else {
        this.$message.warning('暂无操作权限');
      }
    },
    // 获取配置信息
    getConfigInfo: function getConfigInfo() {
      var _this2 = this;
      this.keyNum += 1;
      this.loading = true;
      (0, _community.communityConfigApi)().then(function (res) {
        _this2.editData = res;
        _this2.loading = false;
      }).catch(function () {
        _this2.loading = false;
      });
    }
  }
};