var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "borderPadding" }, [
    _vm.configData
      ? _c("div", { staticClass: "acea-row row-middle mb20" }, [
          _c("div", { staticClass: "c_label labelwidth" }, [
            _vm._v(_vm._s(_vm.configData.title)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "color-box ml22" },
            _vm._l(_vm.configData.color, function (color, key) {
              return _c(
                "div",
                { key: key, staticClass: "color-item acea-row row-middle" },
                [
                  _c("el-color-picker", {
                    staticStyle: { "vertical-align": "middle" },
                    attrs: { alpha: "", "show-alpha": "" },
                    on: {
                      change: function ($event) {
                        return _vm.changeColor($event, color)
                      },
                    },
                    model: {
                      value: color.item,
                      callback: function ($$v) {
                        _vm.$set(color, "item", $$v)
                      },
                      expression: "color.item",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "ml10",
                    staticStyle: { width: "78%" },
                    attrs: { size: "small" },
                    model: {
                      value: color.item,
                      callback: function ($$v) {
                        _vm.$set(color, "item", $$v)
                      },
                      expression: "color.item",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.resetBgA(color, _vm.index, key)
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                  _vm._v(" "),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: key === 0 && _vm.configData.color.length > 1,
                        expression: "key === 0 && configData.color.length > 1",
                      },
                    ],
                    staticClass: "iconfont icon-lianjie",
                  }),
                ],
                1
              )
            }),
            0
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }