var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox", staticStyle: { "padding-top": "0" } },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          attrs: { "body-style": { paddingLeft: "0px", paddingBottom: "0px" } },
        },
        [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "hasPermi",
                    rawName: "v-hasPermi",
                    value: ["platform:system:group:list"],
                    expression: "['platform:system:group:list']",
                  },
                ],
                staticClass: "tab_view",
              },
              _vm._l(_vm.tabList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "cell_item",
                    class: { tab_active: _vm.listActive == index },
                    on: {
                      click: function ($event) {
                        return _vm.ProductNavTab(index)
                      },
                    },
                  },
                  [_vm._v("\n          " + _vm._s(item) + "\n        ")]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "leftModel" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentPage == "home",
                      expression: "currentPage == 'home'",
                    },
                  ],
                  staticClass: "current_home",
                },
                [
                  _c("div", { staticClass: "model_header" }, [
                    _c("div", { staticClass: "img" }, [_vm._v("logo区域")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "header_search" }, [
                      _c("span", { staticClass: "iconfont iconios-search" }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["platform:system:group:list"],
                          expression: "['platform:system:group:list']",
                        },
                      ],
                      staticClass: "model_banner cur_pointer",
                    },
                    [
                      _c(
                        "el-carousel",
                        {
                          attrs: {
                            "indicator-position": "none",
                            height: "139px",
                          },
                        },
                        _vm._l(_vm.dataList[1], function (item, index) {
                          return _c("el-carousel-item", { key: index }, [
                            _c("img", {
                              class: { select_ctive: _vm.shows == 1 },
                              staticStyle: {
                                width: "100%",
                                "border-radius": "4px",
                              },
                              attrs: { src: item.pic, alt: "" },
                            }),
                          ])
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "model_news cur_pointer",
                      class: { select_ctive: _vm.shows == 2 },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "64px", height: "17px" },
                        attrs: {
                          src: require("@/assets/imgs/new_header1.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "#ccc" } }, [
                        _vm._v("|"),
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(_vm.newsInfo))]),
                      _vm._v(" "),
                      _c("i", { staticClass: "el-icon-arrow-right" }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "model_nav cur_pointer",
                      class: { select_ctive: _vm.shows == 3 },
                    },
                    _vm._l(_vm.dataList[0], function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "model_nav_item" },
                        [
                          _c("div", [
                            _c("img", { attrs: { src: item.pic, alt: "" } }),
                          ]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(item.name))]),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "moni_goods cur_pointer",
                      class: { select_ctive: _vm.shows == 7 },
                    },
                    [_c("img", { attrs: { src: _vm.mockGoodsImg, alt: "" } })]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["platform:system:group:list"],
                          expression: "['platform:system:group:list']",
                        },
                      ],
                      staticClass: "page-foot cur_pointer",
                      on: {
                        click: function ($event) {
                          return _vm.handleMessage("bottomNavigation")
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "page-fooot",
                          class: { select_ctive: _vm.shows == 8 },
                        },
                        _vm._l(_vm.navigationListTab, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "foot-item" },
                            [
                              _c("el-image", {
                                staticClass: "el-image",
                                attrs: { src: item.checked, alt: "" },
                              }),
                              _vm._v(" "),
                              index == 0
                                ? _c("p", { staticClass: "fontColor3" }, [
                                    _vm._v(_vm._s(item.name)),
                                  ])
                                : _c("p", [_vm._v(_vm._s(item.name))]),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentPage == "cate",
                      expression: "currentPage == 'cate'",
                    },
                  ],
                },
                [
                  _c("img", {
                    staticStyle: { width: "100%" },
                    attrs: { src: _vm.cateImg, alt: "" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentPage == "user",
                      expression: "currentPage == 'user'",
                    },
                  ],
                },
                [
                  _c("div", { staticClass: "user_head" }, [
                    _c(
                      "div",
                      {
                        staticClass: "user_bg",
                        style: { backgroundImage: "url(" + _vm.urlbg + ")" },
                      },
                      [
                        _c("div", { staticClass: "user_card" }, [
                          _c("div", { staticClass: "user_info" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.menuInfo.userDefaultAvatar,
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "info" }, [
                              _c("p", { staticClass: "nick_name" }, [
                                _vm._v("用户信息"),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "phone" }, [
                                _vm._v("123456"),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "num_wrapper" }, [
                            _c("div", { staticClass: "num_wrap_item" }, [
                              _c("p", { staticClass: "num_item_bold" }, [
                                _vm._v("0"),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "num_title" }, [
                                _vm._v("余额"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "num_wrap_item" }, [
                              _c("p", { staticClass: "num_item_bold" }, [
                                _vm._v("0"),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "num_title" }, [
                                _vm._v("积分"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "num_wrap_item" }, [
                              _c("p", { staticClass: "num_item_bold" }, [
                                _vm._v("0"),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "num_title" }, [
                                _vm._v("优惠券"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "num_wrap_item" }, [
                              _c("p", { staticClass: "num_item_bold" }, [
                                _vm._v("0"),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "num_title" }, [
                                _vm._v("收藏"),
                              ]),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "order_wrap" }, [
                          _c("div", { staticClass: "order_wrap_tit" }, [
                            _c("span", { staticClass: "weight_600" }, [
                              _vm._v("订单中心"),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("span", { staticClass: "font_sm" }, [
                                _vm._v("查看全部"),
                              ]),
                              _vm._v(" "),
                              _c("i", { staticClass: "el-icon-arrow-right" }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "order_wrap_list" }, [
                            _c("div", { staticClass: "order_list_item" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/imgs/fukuan.png"),
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c("p", [_vm._v("待付款")]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "order_list_item" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/imgs/fahuo.png"),
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c("p", [_vm._v("待发货")]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "order_list_item" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/imgs/shouhuo.png"),
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c("p", [_vm._v("待收货")]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "order_list_item" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/imgs/pingjia.png"),
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c("p", [_vm._v("待评价")]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "order_list_item" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/imgs/tuikuan.png"),
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c("p", [_vm._v("售后/退款")]),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["platform:system:group:list"],
                                expression: "['platform:system:group:list']",
                              },
                            ],
                            staticClass: "slider_img cur_pointer",
                            class: { select_ctive: _vm.shows == 5 },
                            on: {
                              click: function ($event) {
                                return _vm.handleMessage("userBanner")
                              },
                            },
                          },
                          [
                            _c(
                              "el-carousel",
                              { attrs: { height: "69px", autoplay: true } },
                              _vm._l(_vm.dataList[4], function (item, index) {
                                return _c("el-carousel-item", { key: index }, [
                                  _c("img", {
                                    staticStyle: {
                                      height: "69px",
                                      display: "block",
                                      margin: "auto",
                                    },
                                    attrs: { src: item.pic, alt: "" },
                                  }),
                                ])
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "user_mens cur_pointer",
                            class: { select_ctive: _vm.shows == 6 },
                            on: {
                              click: function ($event) {
                                return _vm.handleMessage("userMenu")
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "menu_title" }, [
                              _vm._v("我的服务"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "list_box" },
                              _vm._l(_vm.dataList[2], function (item, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "list_box_item" },
                                  [
                                    _c("img", {
                                      attrs: { src: item.pic, alt: "" },
                                    }),
                                    _vm._v(" "),
                                    _c("p", [_vm._v(_vm._s(item.name))]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex_between" },
              [
                _vm.typeName
                  ? _c("div", { staticClass: "right-box" }, [
                      _c("div", { staticClass: "title-bar" }, [
                        _vm._v("模块配置"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mobile-config" },
                        [
                          _vm.typeName === "bottomNavigation" &&
                          _vm.checkPermi(["platform:system:group:list"])
                            ? [
                                _c("span", { staticClass: "mr20" }, [
                                  _vm._v("是否自定义"),
                                ]),
                                _c("el-switch", {
                                  attrs: {
                                    "active-value": 1,
                                    "inactive-value": 0,
                                    "active-text": "开启",
                                    "inactive-text": "关闭",
                                  },
                                  model: {
                                    value: _vm.isCustom,
                                    callback: function ($$v) {
                                      _vm.isCustom = $$v
                                    },
                                    expression: "isCustom",
                                  },
                                }),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.navigationList,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      { key: index, staticClass: "box-item" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "left-tool" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "move-icon",
                                                attrs: { draggable: "true" },
                                                on: {
                                                  dragstart: function ($event) {
                                                    return _vm.handleDragStart(
                                                      $event,
                                                      item
                                                    )
                                                  },
                                                  dragover: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.handleDragOver(
                                                      $event,
                                                      item
                                                    )
                                                  },
                                                  dragenter: function ($event) {
                                                    return _vm.handleDragEnter(
                                                      $event,
                                                      item,
                                                      _vm.navigationList,
                                                      "navigationList"
                                                    )
                                                  },
                                                  dragend: function ($event) {
                                                    return _vm.handleDragEnd(
                                                      $event,
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "iconfont icon-drag2",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "right-wrapper" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "img-wrapper" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "img-item",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.modalPicTap(
                                                          "2",
                                                          "checked",
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    item.checked
                                                      ? _c("img", {
                                                          attrs: {
                                                            src: item.checked,
                                                            alt: "",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    item.checked
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass: "txt",
                                                          },
                                                          [_vm._v("选中")]
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "empty-img",
                                                          },
                                                          [
                                                            _c("span", {
                                                              staticClass:
                                                                "iconfont iconjiahao",
                                                            }),
                                                            _vm._v(" "),
                                                            _c("p", [
                                                              _vm._v("选中"),
                                                            ]),
                                                          ]
                                                        ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "img-item",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.modalPicTap(
                                                          "3",
                                                          "unchecked",
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    item.unchecked
                                                      ? _c("img", {
                                                          attrs: {
                                                            src: item.unchecked,
                                                            alt: "",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    item.unchecked
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass: "txt",
                                                          },
                                                          [_vm._v("未选中")]
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "empty-img",
                                                          },
                                                          [
                                                            _c("span", {
                                                              staticClass:
                                                                "iconfont iconjiahao",
                                                            }),
                                                            _vm._v(" "),
                                                            _c("p", [
                                                              _vm._v("未选中"),
                                                            ]),
                                                          ]
                                                        ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "info mt20",
                                                staticStyle: {
                                                  "margin-left": "0",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "info-item" },
                                                  [
                                                    _c("span", [
                                                      _vm._v("标题"),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "input-box",
                                                      },
                                                      [
                                                        _vm.typeName !==
                                                        "indexNews"
                                                          ? _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "请填写" +
                                                                  item.name,
                                                                maxlength: "4",
                                                              },
                                                              model: {
                                                                value:
                                                                  item.name,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "name",
                                                                      typeof $$v ===
                                                                        "string"
                                                                        ? $$v.trim()
                                                                        : $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.name",
                                                              },
                                                            })
                                                          : _c("el-input", {
                                                              attrs: {
                                                                placeholder:
                                                                  "请填写" +
                                                                  item.info,
                                                              },
                                                              model: {
                                                                value:
                                                                  item.info,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "info",
                                                                      typeof $$v ===
                                                                        "string"
                                                                        ? $$v.trim()
                                                                        : $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.info",
                                                              },
                                                            }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "info-item" },
                                                  [
                                                    _c("span", [
                                                      _vm._v("链接"),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "input-box",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.getLink(
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            placeholder:
                                                              "请填写链接",
                                                          },
                                                          model: {
                                                            value: item.link,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "link",
                                                                typeof $$v ===
                                                                  "string"
                                                                  ? $$v.trim()
                                                                  : $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.link",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "info-item" },
                                                  [
                                                    _c("span", [
                                                      _vm._v("状态"),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "input-box",
                                                      },
                                                      [
                                                        _c("el-switch", {
                                                          attrs: {
                                                            "active-value": true,
                                                            "inactive-value": false,
                                                            "active-text":
                                                              "显示",
                                                            "inactive-text":
                                                              "隐藏",
                                                          },
                                                          model: {
                                                            value: item.status,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "status",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.status",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: index > 0,
                                                expression: "index > 0",
                                              },
                                            ],
                                            staticClass: "delect-btn",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.handleDelMenu(
                                                  item,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-circle-close",
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.typeName !== "bottomNavigation" &&
                          _vm.checkPermi(["platform:system:group:list"])
                            ? _vm._l(_vm.menuList, function (item, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "item" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "move-icon",
                                        attrs: { draggable: "true" },
                                        on: {
                                          dragstart: function ($event) {
                                            return _vm.handleDragStart(
                                              $event,
                                              item
                                            )
                                          },
                                          dragover: function ($event) {
                                            $event.preventDefault()
                                            return _vm.handleDragOver(
                                              $event,
                                              item
                                            )
                                          },
                                          dragenter: function ($event) {
                                            return _vm.handleDragEnter(
                                              $event,
                                              item,
                                              _vm.menuList,
                                              "menuList"
                                            )
                                          },
                                          dragend: function ($event) {
                                            return _vm.handleDragEnd(
                                              $event,
                                              item
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "iconfont icon-drag2",
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.typeName !== "indexNews"
                                      ? _c("div", { staticClass: "picBox" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "img-box flex justify-center align-center",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.modalPicTap(
                                                    "1",
                                                    "duo",
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              item.pic
                                                ? _c("img", {
                                                    attrs: {
                                                      src: item.pic,
                                                      alt: "",
                                                    },
                                                  })
                                                : _c(
                                                    "div",
                                                    { staticClass: "upLoad" },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-camera cameraIconfont",
                                                      }),
                                                    ]
                                                  ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    index > 0 && _vm.typeName !== "indexTabNav"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "delect-btn",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.bindDelete(
                                                  item,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-circle-close",
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "info" }, [
                                      _vm.typeName !== "userBanner"
                                        ? _c(
                                            "div",
                                            { staticClass: "info-item" },
                                            [
                                              _c("span", [_vm._v("标题")]),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "input-box" },
                                                [
                                                  _vm.typeName !== "indexNews"
                                                    ? _c("el-input", {
                                                        attrs: {
                                                          placeholder:
                                                            "请填写" +
                                                            item.name,
                                                          maxlength: "4",
                                                        },
                                                        model: {
                                                          value: item.name,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "name",
                                                              typeof $$v ===
                                                                "string"
                                                                ? $$v.trim()
                                                                : $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.name",
                                                        },
                                                      })
                                                    : _c("el-input", {
                                                        attrs: {
                                                          placeholder:
                                                            "请填写" +
                                                            item.info,
                                                        },
                                                        model: {
                                                          value: item.info,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "info",
                                                              typeof $$v ===
                                                                "string"
                                                                ? $$v.trim()
                                                                : $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.info",
                                                        },
                                                      }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.addUrlStatus &&
                                      _vm.typeName !== "indexTabNav"
                                        ? _c(
                                            "div",
                                            { staticClass: "info-item" },
                                            [
                                              _c("span", [_vm._v("链接")]),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "input-box",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.getLink(index)
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder: "请填写链接",
                                                    },
                                                    model: {
                                                      value: item.url,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "url",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression: "item.url",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.typeName == "indexTabNav"
                                        ? _c(
                                            "div",
                                            { staticClass: "info-item" },
                                            [
                                              _c("span", [_vm._v("简介")]),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "input-box" },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder: "请填写简介",
                                                    },
                                                    model: {
                                                      value: item.info,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "info",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression: "item.info",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "info-item" }, [
                                        _c("span", [_vm._v("状态")]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "input-box" },
                                          [
                                            _c("el-switch", {
                                              attrs: {
                                                "active-value": true,
                                                "inactive-value": false,
                                                "active-text": "显示",
                                                "inactive-text": "隐藏",
                                              },
                                              model: {
                                                value: item.status,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "status", $$v)
                                                },
                                                expression: "item.status",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]),
                                  ]
                                )
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "add-btn mb20 mt20" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.addBox },
                                },
                                [_vm._v("添加数据")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("linkaddress", {
                  ref: "linkaddres",
                  on: { linkUrl: _vm.linkUrl },
                }),
                _vm._v(" "),
                !_vm.typeName && _vm.tip == true && _vm.cate == false
                  ? _c("div", { staticClass: "tip" }, [
                      _vm._v("请选择左侧可操作可编辑区域"),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          !_vm.mockGoods
            ? _c(
                "div",
                { staticClass: "footer_btn" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: [
                            "platform:page:layout:index",
                            "platform:page:layout:index:menu:save",
                            "platform:page:layout:index:banner:save",
                            "platform:page:layout:index:banner:save",
                            "platform:page:layout:user:menu:save",
                            "platform:page:layout:bottom:navigation",
                            "platform:page:layout:bottom:navigation:save",
                          ],
                          expression:
                            "[\n          'platform:page:layout:index',\n          'platform:page:layout:index:menu:save',\n          'platform:page:layout:index:banner:save',\n          'platform:page:layout:index:banner:save',\n          'platform:page:layout:user:menu:save',\n          'platform:page:layout:bottom:navigation',\n          'platform:page:layout:bottom:navigation:save',\n        ]",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.saveConfig },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }