import { render, staticRenderFns } from "./index.vue?vue&type=template&id=77408b10"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=77408b10&lscoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\workspace\\mer_plat_admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('77408b10')) {
      api.createRecord('77408b10', component.options)
    } else {
      api.reload('77408b10', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=77408b10", function () {
      api.rerender('77408b10', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pagediy/theme/index.vue"
export default component.exports