var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setUp borderPadding" },
    [
      [
        _c(
          "el-tabs",
          {
            model: {
              value: _vm.configData.tabVal,
              callback: function ($$v) {
                _vm.$set(_vm.configData, "tabVal", $$v)
              },
              expression: "configData.tabVal",
            },
          },
          [
            _c("el-tab-pane", { attrs: { label: "内容设置" } }),
            _vm._v(" "),
            _c("el-tab-pane", { attrs: { label: "样式设置" } }),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }