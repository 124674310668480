var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c(
        "el-row",
        { attrs: { type: "flex" } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form",
                {
                  ref: "formInline",
                  staticClass: "login-form",
                  attrs: {
                    size: "small",
                    model: _vm.formInline,
                    rules: _vm.ruleInline,
                    autocomplete: "on",
                    "label-position": "left",
                  },
                },
                [
                  _c("div", { staticClass: "title-container" }, [
                    _c("h3", { staticClass: "title mb15" }, [
                      _vm._v("短信账户登录"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "account" } },
                    [
                      _c("el-input", {
                        ref: "account",
                        attrs: {
                          placeholder: "用户名",
                          "prefix-icon": "el-icon-user",
                          name: "username",
                          type: "text",
                          tabindex: "1",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.formInline.account,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "account", $$v)
                          },
                          expression: "formInline.account",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "password" } },
                    [
                      _c("el-input", {
                        key: _vm.passwordType,
                        ref: "password",
                        attrs: {
                          type: _vm.passwordType,
                          placeholder: "密码",
                          name: "password",
                          tabindex: "2",
                          "auto-complete": "off",
                          "prefix-icon": "el-icon-lock",
                        },
                        model: {
                          value: _vm.formInline.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "password", $$v)
                          },
                          expression: "formInline.password",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "show-pwd", on: { click: _vm.showPwd } },
                        [
                          _c("svg-icon", {
                            attrs: {
                              "icon-class":
                                _vm.passwordType === "password"
                                  ? "eye"
                                  : "eye-open",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["platform:one:pass:login"],
                          expression: "['platform:one:pass:login']",
                        },
                      ],
                      staticStyle: { width: "100%", "margin-bottom": "20px" },
                      attrs: {
                        size: "mini",
                        loading: _vm.loading,
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit("formInline")
                        },
                      },
                    },
                    [_vm._v("登录\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "acea-row row-center-wrapper mb20" },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["platform:one:pass:update:password"],
                              expression:
                                "['platform:one:pass:update:password']",
                            },
                          ],
                          staticStyle: { "margin-left": "0" },
                          attrs: { size: "mini", type: "text" },
                          on: { click: _vm.changePassword },
                        },
                        [_vm._v("忘记密码")]
                      ),
                      _vm._v(" "),
                      _c("el-divider", { attrs: { direction: "vertical" } }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["platform:one:pass:register"],
                              expression: "['platform:one:pass:register']",
                            },
                          ],
                          staticStyle: { "margin-left": "0" },
                          attrs: { size: "mini", type: "text" },
                          on: { click: _vm.changeReg },
                        },
                        [_vm._v("注册账户")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content:
                          "\n            一号通为我司一个第三方平台\n            专门提供短信 ， 物流查询，商品复制等个性化服务\n            省去了自己单独接入功能的麻烦\n            初次运行代码默认是没有账号的，需要自行注册，\n            登录成功后根据提示购买自己需要用到的服务即可",
                        placement: "bottom",
                      },
                    },
                    [
                      _c("span", { staticStyle: { "margin-left": "0" } }, [
                        _vm._v("平台说明"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }